/* eslint-disable class-methods-use-this */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal'];

  toggleModal() {
    const modal = document.getElementById('testingReferralModal');
    modal.classList.toggle('opacity-0');
    modal.classList.toggle('pointer-events-none');
  }

  updateModalField(e) {
    const currentFieldId = e.currentTarget.id;
    const currentFieldValue = e.currentTarget.value;

    document.getElementById(`modal_${currentFieldId}`).value = currentFieldValue;
  }

  updateModalCheckboxField(e) {
    const currentFieldId = e.currentTarget.id;

    if (e.currentTarget.checked) {
      document.getElementById(`modal_${currentFieldId}`).checked = true;
    } else {
      document.getElementById(`modal_${currentFieldId}`).checked = false;
    }
  }
}
