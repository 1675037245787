$(document).on('turbolinks:load', () => {
  $('.employers').toggle($('.share-checkbox').is(':checked'));

  $('.share-checkbox').on('change', () => {
    if ($('.share-checkbox:checked').val()) {
      $('.employers').show();
    } else {
      $('.employers').hide();
    }
  });
});
