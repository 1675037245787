import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['lotNumberToolTip'];

  showInformation() {
    this.lotNumberToolTipTarget.classList.remove('hidden');
  }

  hideInformation() {
    this.lotNumberToolTipTarget.classList.add('hidden');
  }
}
