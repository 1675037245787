/* eslint-disable no-alert */

$(() => {
  $('.delete-inventory').on('click', (event) => {
    if (!$(event.target).data('deleteable')) {
      alert('This item cannot be deleted because it is being used.');
      event.preventDefault();
      return false;
    }

    return true;
  });
});
