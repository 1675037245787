/* global Dropdown */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'options'];

  initialize() {
    this.dropdownInstance = null;
  }

  connect() {
    this.createDropdown();
  }

  createDropdown() {
    this.dropdownInstance = new Dropdown(this.optionsTarget, this.buttonTarget);
    this.dropdownInstance.hide();
  }

  hideDropdown(event) {
    event.preventDefault();
    this.dropdownInstance.hide();
  }
}
