/* eslint-disable class-methods-use-this */

import { Controller } from '@hotwired/stimulus';
import * as ActionCable from '@rails/actioncable';

export default class extends Controller {
  static targets = ['button'];

  connect() {
    ActionCable.logger.enabled = document.body.getAttribute('data-env') !== 'production';
  }

  selectAppointment(event) {
    const that = this;

    this.buttonTarget.disabled = true;

    const appointmentSubscription = {
      connected() {
        const form = $('#on-boarding-form')[0];
        const formData = new FormData(form);

        const data = {};
        formData.forEach((entry) => {
          const [key, value] = entry;
          data[key] = value;
        });
        data.clinic_id = that.data.get('clinicIdValue');
        data.patient_id = that.data.get('patientIdValue');
        data.patient_token = that.data.get('patientTokenValue');

        this.perform('select_appointment', data);
      },
      received(data) {
        switch (data.status) {
          case 'accept':
          case 'reject':
            window.location = data.next;
            break;
          default:
            that.showFlash('Unexpected failure; please reload the page and try again.');
            break;
        }
      },
    };
    const consumer = ActionCable.createConsumer(
      `${ActionCable.getConfig('url')}?token=${that.data.get('tokenValue')}`,
    );
    consumer.subscriptions.create({
      channel: 'AppointmentChannel',
    }, appointmentSubscription);

    return event.preventDefault();
  }

  showFlash(message) {
    const flashTemplate = document.getElementById('template-flash-message');
    const newFlashMessage = flashTemplate.content.firstElementChild.cloneNode(true);
    const theMessage = newFlashMessage.getElementsByTagName('p')[0];

    theMessage.innerHTML = message;

    document.body.appendChild(newFlashMessage);
  }
}
