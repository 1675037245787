import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

// This controller is par of the Enterprise Clinic Inventory Search
// (clinic_inventory_search flag) feature. This feature is built using
// three stimulus controllers:
// - EnterpriseClinicInventoryController
// - EnterpriseClinicInventorySearchController
// - EnterpriseClinicInventorySearchWithResultsController
//
// This controller(EnterpriseClinicInventorySearchController) is used to handle the search
// widget. A text field is shown where the use can type keywords to find supply
// inventories. Once the user clicks on the Search button, this controller makes
// a backend request sending the typed keywords. Once the results are back, this
// controller dispatches the resultsReady event with the supply inventories
// returned by the backend and the query (keywords) used.
//
// NOTE: to knmow more about how the feature works, look at the
// EnterpriseClinicInventoryController which has an overall description of the feature,
// and EnterpriseClinicInventorySearchWithResultsController for some more notes.

export default class extends Controller {
  static targets = ['searchField'];

  static queryFields = 'supply_inventory_search_in';

  search(event) {
    event.preventDefault();

    const formData = new FormData();
    formData.append('supply_inventory_search', this.searchFieldTarget.value);

    Rails.ajax({
      type: 'post',
      url: '/supply_inventories/search',
      data: formData,
      success: this.onSuccess,
    });
  }

  onSuccess = (response) => {
    this.dispatch('resultsReady', { detail: { supplyInventories: response, query: this.searchFieldTarget.value } });
  };

  cleanSearchField() {
    this.searchFieldTarget.value = '';
  }

  onKeyPress(event) {
    // Fire search when Enter is pressed.
    if (event.keyCode === 13) {
      this.search(event);
      return false;
    }

    return true;
  }
}
