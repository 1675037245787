import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['confirmModal', 'form', 'message'];

  connect() {
    this.targetChange();
  }

  // eslint-disable-next-line class-methods-use-this
  targetChange() {
    const userBlock = $('.user-block');
    const clinicsBlock = $('.clinics-block');

    if ($('.target:checked').val() === 'users') {
      userBlock.show();
      userBlock.find('.chosen-container').css('width', '100%');
      userBlock.find('select').attr('required', true);
      userBlock.find('select').css('height', 30);
      userBlock.find('select').css('width', $('.chosen-container').width());
      clinicsBlock.find('select').attr('required', false);
      clinicsBlock.hide();
      $('.patient-type').attr('required', false);
    } else {
      userBlock.hide();
      userBlock.find('select').attr('required', false);
      clinicsBlock.find('select').attr('required', true);
      clinicsBlock.show();
      clinicsBlock.find('.chosen-container').css('width', '100%');
      $('.patient-type').attr('required', true);
    }
  }

  confirmSubmit(e) {
    if ($(this.formTarget).is(':valid')) {
      const { confirmModalTarget, messageTarget } = this;
      e.preventDefault();

      $.ajax({
        url: '/messages/details',
        type: 'JSON',
        data: $(this.formTarget).serialize(),
        method: 'POST',
        success(options) {
          const plural = options === 1 ? 'person' : 'people';

          // eslint-disable-next-line max-len
          const message = `This will send email to ${options} ${plural}, do you still want to continue?`;

          $(messageTarget).html(message);
          confirmModalTarget.classList.remove('hidden');
        },
      });
    }
  }

  dismissModal() {
    this.confirmModalTarget.classList.add('hidden');
  }

  submitForm() {
    this.confirmModalTarget.classList.add('hidden');
    window.onbeforeunload = null;
    $(this.formTarget).submit();
  }
}
