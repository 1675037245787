import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['parent', 'children', 'companyInputField', 'organization', 'orgOptions', 'venueOptions'];

  connect() {
    if (this.hasOrganizationTarget) {
      if (this.data.get('multiple')) {
        this.multiple_organization();
      } else {
        this.update();
      }
    } else {
      this.update_without_organization();
    }
    this.bind_chosen();
  }

  chosenChange(e) {
    if (e.bubbles) {
      e.preventDefault();
      return;
    }

    if (e.target.name === 'clinic[venue_id]') {
      populateAddressFields(); /* eslint no-use-before-define: 0 */
    }
    const event = new Event('change', { bubbles: true });
    this.dispatchEvent(event);
  }

  bind_chosen() {
    const hasOrganization = this.hasOrganizationTarget;

    if (this.hasParentTarget && !$(this.parentTarget).siblings('.chosen-container').length) {
      $(this.parentTarget).unbind();

      $('select').on('chosen:ready', (event) => {
        const thisTarget = event.target;
        const height = $(thisTarget).next('.chosen-container').height();
        const width = $(thisTarget).next('.chosen-container').width();

        $(thisTarget).css({
          position: 'absolute',
          height,
          width,
          opacity: 0,
        }).show();
      });

      $(this.parentTarget).chosen({
        width: '100%',
      }).change(this.chosenChange);
    }

    if (hasOrganization && !$(this.organizationTarget).siblings('.chosen-container').length) {
      $(this.organizationTarget).chosen({
        width: '100%',
      }).change(this.chosenChange);
    }

    if (this.hasChildrenTarget && !$(this.childrenTarget).siblings('.chosen-container').length) {
      $(this.childrenTarget).chosen({
        width: '100%',
      }).change(this.chosenChange);
    }

    $('.select-all').on('click', (event) => {
      const thisTarget = event.target;
      $(thisTarget).closest('.flex').find('select option').prop('selected', true);
      const select = $(thisTarget).closest('.flex').find('select');
      select.trigger('chosen:updated');
      const changeEvent = new Event('change', { bubbles: true });
      $(select)[0].dispatchEvent(changeEvent);
    });
  }

  update_without_organization() {
    if (this.hasChildrenTarget) {
      this.update_venues(false);
    }
  }

  update() {
    const id = $(this.parentTarget).val();
    const hasOrganization = this.hasOrganizationTarget;
    const organization = this.organizationTarget;
    if (hasOrganization) {
      $(this.organizationTarget).html('');
    }

    if (id.length > 0) {
      $.ajax({
        url: `/counties/${id}/organizations`,
        method: 'GET',
        success: (options) => {
          let newOptions = '<option></option>';
          $.each(options, (e) => {
            newOptions += `<option value="${options[e][1]}">${options[e][0]}</option>`;
          });
          $(organization).html(newOptions);

          if ($('#organization_id').val() > 0) {
            $(`.org-select option[value=${$('#organization_id').val()}]`).prop('selected', true);
          }
          $(organization).trigger('chosen:updated').trigger('change');
        },
      });
    } else {
      if (hasOrganization) {
        $(this.organizationTarget).html('');
        if (this.hasChildrenTarget) {
          $(this.childrenTarget).html('');
        }
      }
      $(organization).trigger('chosen:updated');
    }
  }

  update_venues(organization = true) {
    let id;
    let path;
    if (organization === false) {
      id = $(this.parentTarget).val();
      path = `/counties/${id}/venue_json`;
    } else {
      id = $(this.organizationTarget).val();
      path = `/organizations/${id}/venue_json`;
    }

    const venues = this.childrenTarget;
    $(this.childrenTarget).html('');

    if (id && id > 0) {
      $.ajax({
        url: path,
        method: 'GET',
        success: (options) => {
          let newOptions = '<option></option>';
          $.each(options, (e) => {
            newOptions += `<option value="${options[e][1]}">${options[e][0]}</option>`;
          });
          $(venues).html(newOptions);
          if ($('#venue_id').val() > 0) {
            $(`.venue-select option[value=${$('#venue_id').val()}]`).attr('selected', true);
          }

          if ($('.nested:visible')) {
            let i;
            for (i = 0; i < $('.nested:visible').length; i++) {
              if ($(`#venue_id_${i}`).val()) {
                $(`.nested:visible .venue-select-${i} option[value=${$(`#venue_id_${i}`).val()}]`).attr('selected', true);
              }
            }
          }

          $(venues).trigger('chosen:updated');
        },
      });
    } else {
      $(this.childrenTarget).html('');
      $(venues).trigger('chosen:updated');
    }
  }

  multiple_organization() {
    const ids = $(this.parentTarget).val();
    const selected = $(this.parentTarget).data('selected');
    const organization = this.organizationTarget;
    $(this.organizationTarget).html('');

    if (ids && ids.length) {
      const options = JSON.parse($(this.orgOptionsTarget).val());
      let selectOptions = '';
      ids.forEach((id) => {
        if (options[id]) {
          options[id].forEach((e) => {
            const selectedOption = $.inArray(e[1], selected) > -1 ? 'selected' : '';
            selectOptions += `<option value="${e[1]}" ${selectedOption}>${e[0]}</option>`;
          });
        }
      });
      $(organization).html(selectOptions);
      $(organization).trigger('chosen:updated');
    } else {
      $(this.organizationTarget).html('');
      $(this.childrenTarget).html('');
    }
    this.multiple_venues();
    $(organization).trigger('chosen:updated');
  }

  multiple_venues() {
    const ids = $(this.organizationTarget).val();
    const selected = $(this.organizationTarget).data('selected');
    const venues = this.childrenTarget;
    $(this.childrenTarget).html('');

    if (ids && ids.length) {
      const options = JSON.parse($(this.venueOptionsTarget).val());
      let selectOptions = '';
      ids.forEach((id) => {
        if (options[id]) {
          options[id].forEach((e) => {
            const selectedOption = $.inArray(e[1], selected) > -1 ? 'selected' : '';
            selectOptions += `<option value="${e[1]}" ${selectedOption}>${e[0]}</option>`;
          });
        }
      });
      $(venues).html(selectOptions);
      $(venues).trigger('chosen:updated');
    } else {
      $(this.childrenTarget).html('');
    }
    $(venues).trigger('chosen:updated');
  }
}

function populateAddressFields() {
  const id = $('#venue-select').children('option:selected').val();
  const autoPopulateAddress = $('.auto-populate-address').length > 0;
  if (id && autoPopulateAddress && id > 0) {
    $.ajax({
      url: `/venues/${id}.json`,
      method: 'GET',
      success: (response) => {
        if (response.city) {
          $('#locality').val(response.city);
        }
        if (response.state) {
          $('#administrative_area_level_1').val(response.state);
        }
        if (response.zip_code) {
          $('#postal_code').val(response.zip_code);
        }
        if (response.address) {
          $('#autocomplete').val(response.address);
        }
      },
    });
  }
}
