/* eslint-disable class-methods-use-this */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal', 'openCheckinModal', 'eventModalButton'];

  toggleTextArea(e) {
    const currentCheckbox = e.currentTarget;
    const currentTextarea = $(`[data-textarea='${e.currentTarget.getAttribute('data-textarea')}']`)[1];

    if (e.currentTarget.checked) {
      $(currentTextarea).val($(currentCheckbox).val());
      $(currentTextarea).text($(currentCheckbox).val());
      $(currentTextarea).addClass('invisible');
    } else {
      $(currentTextarea).val('');
      $(currentTextarea).text('');
      $(currentTextarea).removeClass('invisible');
    }
  }

  setStatus(e) {
    document.getElementById('provider_enrollment_status').value = e.currentTarget.getAttribute('data-status');
  }

  setDeniedStatus() {
    const statusField = document.getElementById('provider_enrollment_status');
    statusField.value = 'denied';
  }

  toggleModal() {
    this.modalTarget.classList.toggle('opacity-0');
    this.modalTarget.classList.toggle('pointer-events-none');
  }

  toggleOpenCheckinModal(e) {
    this.openCheckinModalTarget.classList.toggle('hidden');
    if (e.currentTarget.hasAttribute('data-event') && e.currentTarget.hasAttribute('data-btnlabel')) {
      this.eventModalButtonTarget.setAttribute('href', `/clinics/${e.currentTarget.dataset.clinic}/queued_patients/update_clinic_status?event=${e.currentTarget.dataset.event}`);
      this.eventModalButtonTarget.textContent = e.currentTarget.dataset.btnlabel;
      document.getElementsByClassName('eventCapitalized')[0].innerHTML = e.currentTarget.dataset.eventcapitalized;
    }
  }
}
