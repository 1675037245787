/* eslint-disable no-alert */

$(document).on('turbolinks:load', () => {
  $('.archive-inventories').on('click', (event) => {
    event.preventDefault();
    const supplyInventoryIds = [];

    $('.supply-inventory-checkbox:checkbox:checked').each((_i, e) => {
      supplyInventoryIds.push($(e).data('supplyInventoryId'));
    });

    if (supplyInventoryIds.length === 0) {
      alert('Please select at least one inventory item to archive.');
      return false;
    }

    $('#supply_inventories_ids').val(supplyInventoryIds);
    $('#archive-supply-inventories-form').submit();

    return true;
  });
});
