/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkboxes', 'checkAll', 'submitDeactivate'];

  syncInvitations() {
    const anyChecked = this.checkboxesTargets.some(
      (checkbox) => checkbox.checked,
    );

    this.submitDeactivateTarget.disabled = !anyChecked;
  }

  handleCheckAll() {
    const newCheckState = this.checkAllTarget.checked;

    this.checkboxesTargets.forEach((checkbox) => {
      if (!checkbox.disabled) {
        checkbox.checked = newCheckState;
      }
    });
    this.syncInvitations();
  }
}
