import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['venueInfoContainer', 'venueInfo'];

  showVenueInfo(event) {
    if (!this.venueInfoContainerTarget) return;

    const that = this;
    Rails.ajax({
      url: `/venues/${event.target.value}`,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        if (data.location) {
          that.venueInfoTarget.innerHTML = data.location;
          that.venueInfoContainerTarget.classList.remove('hidden');
        } else {
          that.venueInfoTarget.innerHTML = '';
          that.venueInfoContainerTarget.classList.add('hidden');
        }
      },
    });
  }
}
