const onTabClick = (event) => {
  event.preventDefault();

  const targetClass = event.target.dataset.target;
  const tabTextToActivate = document.getElementsByClassName(targetClass)[0];
  const tabTextsContainer = tabTextToActivate.parentNode;
  const tabsContainer = event.target.parentNode;

  // deactivate existing active tabs and panel
  tabsContainer.querySelectorAll('.tab-item').forEach((tab) => tab.classList.remove('active'));
  tabTextsContainer.querySelectorAll('.tab-item-text').forEach((panel) => panel.classList.remove('active'));

  // activate new tabs and panel
  event.target.classList.add('active');
  tabTextToActivate.classList.add('active');
};

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.tab-item').forEach((tab) => tab.addEventListener('click', onTabClick, false));
});
