import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['userValue'];

  persistUserValue() {
    this.userValueTarget.dataset.previousValue = this.userValueTarget.value;
  }

  revertUserValue() {
    const value = this.userValueTarget.dataset.previousValue;

    if (value) {
      setTimeout(() => {
        document.getElementById('autocomplete').value = value;
      }, 100);
    }
  }
}
