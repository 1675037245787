import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal', 'content'];

  static values = ['hard-stop-answer'];

  validateAnswer(event) {
    const radioButton = event.target;

    if (radioButton.dataset.refusedAnswer === 'true') {
      this.openModal();
      this.hardStopAnswerValue = radioButton;
    }
  }

  openModal() {
    this.modalTarget.classList.remove('hidden');
    this.contentTarget.scrollTop = 0;
  }

  closeModal(event) {
    this.modalTarget.classList.add('hidden');
    event.preventDefault();
  }

  // eslint-disable-next-line class-methods-use-this
  submitFamilyMemberForm(event) {
    const { form } = event.target;
    form.querySelector('#family_member_refusal').value = 'true';
    form.setAttribute('novalidate', 'novalidate');
  }

  unselectAnswer() {
    this.hardStopAnswerValue.checked = false;
    this.hardStopAnswerValue = null;
  }
}
