import { Controller } from '@hotwired/stimulus';
import { RRule, RRuleSet } from 'rrule';

const moment = require('moment');

export default class extends Controller {
  static targets = ['startTime', 'startTimeMeridiem', 'endTimeMeridiem', 'endTime', 'frequency',
    'slots', 'totalAppointments', 'clinicDate', 'clinicBreakStartTime', 'clinicBreakEndTime',
    'clinicBreakStartMeridiem', 'clinicBreakEndMeridiem', 'appointmentsAvailable'];

  static MAX_MERIDIEM_OFFSET_HOURS = 12;

  static NO_MERIDIEM_OFFSET_HOURS = 0;

  connect() {
    this.calculateAppointments();
    this.originalFrequencyOptions = this.frequencyTarget.innerHTML;
    this.originalAppointmentSlotsOptions = this.slotsTarget.innerHTML;
    if (this.frequencyTarget.disabled && this.appointmentsAvailableTarget.value === 'no_walk_in') {
      this.frequencyTarget.innerHTML = '<option>NA</option>';
    }
  }

  toggleTotalAppointments(event) {
    if (event.target.value === 'yes_required') {
      this.frequencyTarget.disabled = this.data.get('appointmentsCount') > 0;
      this.frequencyTarget.innerHTML = this.originalFrequencyOptions;
      this.slotsTarget.innerHTML = this.originalAppointmentSlotsOptions;
      this.slotsTarget.disabled = false;
      this.totalAppointmentsTarget.value = '';
      this.calculateAppointments();
    } else {
      this.frequencyTarget.innerHTML = '<option>NA</option>';
      this.slotsTarget.innerHTML = '<option>NA</option>';
      this.frequencyTarget.disabled = true;
      this.slotsTarget.disabled = true;
      this.totalAppointmentsTarget.value = 'NA';
    }
  }

  calculateAppointments(event) {
    if (event && event.currentTarget.dataset.name === 'add-another') {
      this.totalAppointmentsTarget.value = '';
    } else if (this.clinicDateTargets.length < 3) {
      const slots = this.slotsTarget.value;

      if (this.areClinicBreaksEnabled()) {
        if (slots !== '') {
          this.totalAppointmentsTarget.value = this.validAppointments().length * slots;
        }
      } else {
        const frequency = this.frequencyTarget.value;
        const startTime = `${this.startTimeTarget.value} ${this.startTimeMeridiemTarget.value}`;
        const endTime = `${this.endTimeTarget.value} ${this.endTimeMeridiemTarget.value}`;
        const startMoment = moment(startTime, 'hh:mm A');
        const endMoment = moment(endTime, 'hh:mm A');

        if (frequency !== '' && slots !== '') {
          if (startMoment.isAfter(endMoment)) {
            this.totalAppointmentsTarget.value = 0;
          } else {
            const durationInMinutes = moment.duration(endMoment.diff(startMoment)).asMinutes();
            const totalAppointments = Math.ceil(durationInMinutes / frequency) * slots;
            this.totalAppointmentsTarget.value = totalAppointments;
          }
        }
      }
    }
  }

  areClinicBreaksEnabled() {
    return this.data.get('enterprise_clinic_breaks') === 'true';
  }

  // Returns list of appointments after removing those that fall in any of the
  // clinic breaks.
  validAppointments() {
    const breakRanges = this.breakRanges();

    return this.appointmentList().filter((appointment) => {
      if (breakRanges.length > 0) {
        const breakInConflictWithAppointment = breakRanges.find((breakRange) => {
          const [breakStartTime, breakEndTime] = breakRange;
          return appointment >= breakStartTime && appointment < breakEndTime;
        });

        return !breakInConflictWithAppointment;
      }

      return true;
    });
  }

  // Returns an array of arrays. Each element of the array has a break start time and
  // a end time (in that order)
  breakRanges() {
    return this.validBreaksInfo().map((breakInfo) => {
      const [
        breakStartTime,
        breakStartTimeMeridiem,
        breakEndTime,
        breakEndTimeMeridiem,
      ] = breakInfo;

      return [
        this.buildUtcDate(breakStartTime, breakStartTimeMeridiem),
        this.buildUtcDate(breakEndTime, breakEndTimeMeridiem),
      ];
    });
  }

  // Returns an array of breaks information. Each element of the array consists of the
  // break start time, break start time meridiem, break end time and break end time
  // meridiem. All in that order. We return only those breaks that are valid.
  validBreaksInfo() {
    return this.clinicBreakStartTimeTargets.map((breakStartTimeTarget, i) => {
      const breakStartTime = breakStartTimeTarget;
      const breakStartTimeMeridiem = this.clinicBreakStartMeridiemTargets[i];
      const breakEndTime = this.clinicBreakEndTimeTargets[i];
      const breakEndTimeMeridiem = this.clinicBreakEndMeridiemTargets[i];

      if (!this.isDestroyed(breakStartTime)
        && this.isValidBreakField(breakStartTime) && this.isValidBreakField(breakStartTimeMeridiem)
        && this.isValidBreakField(breakEndTime) && this.isValidBreakField(breakEndTimeMeridiem)) {
        return [
          breakStartTime.value,
          breakStartTimeMeridiem.value,
          breakEndTime.value,
          breakEndTimeMeridiem.value,
        ];
      }

      return null;
    }).filter((breakInfo) => breakInfo !== null);
  }

  isValidBreakField(field) {
    return this.constructor.isValidBreakField(field);
  }

  static isValidBreakField(field) {
    return field.value !== '' && !this.hasErrorClass(field);
  }

  hasErrorClass(field) {
    return this.constructor.hasErrorClass(field);
  }

  static hasErrorClass(field) {
    return field.classList.contains('is-error');
  }

  isDestroyed(field) {
    return this.constructor.isDestroyed(field);
  }

  static isDestroyed(field) {
    const clinicBreakWrapper = field.closest('.clinic-break');
    return clinicBreakWrapper && clinicBreakWrapper.querySelector('.destroyed-element').value === '1';
  }

  // Appintment list calculation. We use the rrule plugin to calculate the
  // list using the clinic start and end date and the frequency given.
  appointmentList() {
    const frequency = parseInt(this.frequencyTarget.value, 10);
    const clinicStartDateTime = this.buildUtcDate(
      this.startTimeTarget.value,
      this.startTimeMeridiemTarget.value,
    );
    const clinicEndDateTime = this.buildUtcDate(
      this.endTimeTarget.value,
      this.endTimeMeridiemTarget.value,
    );

    const rruleSet = new RRuleSet();
    rruleSet.rrule(new RRule({
      freq: RRule.MINUTELY,
      interval: frequency,
      dtstart: clinicStartDateTime,
      until: clinicEndDateTime,
    }));
    // Exclude it so it is not counted as a valid appointment.
    rruleSet.exdate(clinicEndDateTime);

    return rruleSet.all();
  }

  // Build UTC dates, the rrule docs recommend to always use utc dates so
  // things work properly.
  buildUtcDate(time, meridiem) {
    const currentDate = new Date();
    const [hour, minute] = this.parseTime(time);

    return new Date(Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDay(),
      hour + this.meridiemOffsetHours(hour, meridiem),
      minute,
    ));
  }

  parseTime(time) {
    return this.constructor.parseTime(time);
  }

  static parseTime(time) {
    const [hour, minute] = time.split(':');
    return [parseInt(hour, 10), parseInt(minute, 10)];
  }

  meridiemOffsetHours(hour, meridiem) {
    return this.constructor.meridiemOffsetHours(hour, meridiem);
  }

  // Add 12 (MAX_MERIDIEM_OFFSET_HOURS) to the hour when the hour is past noon.
  // Remove 12 (MAX_MERIDIEM_OFFSET_HOURS) to the hour when it's 12 AM
  static meridiemOffsetHours(hour, meridiem) {
    if (meridiem === 'PM' && hour !== 12) {
      return this.MAX_MERIDIEM_OFFSET_HOURS;
    }

    if (meridiem === 'AM' && hour === 12) {
      return -this.MAX_MERIDIEM_OFFSET_HOURS;
    }

    return this.NO_MERIDIEM_OFFSET_HOURS;
  }

  scheduleCalculateAppointments() {
    setTimeout(() => {
      this.calculateAppointments();
    }, 100);
  }
}
