import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'readibillingInput'];

  toggleDisabled(event) {
    const { checked } = event.target;

    this.readibillingInputTargets.forEach((target) => {
      if (checked) {
        target.removeAttribute('disabled');
      } else {
        target.setAttribute('disabled', true);
      }

      if (target.classList.contains('chosen-select')) {
        target.dispatchEvent(new CustomEvent('chosen:updated'));
      }
    });
  }

  selectAll(event) {
    event.preventDefault();
    const { readibillingId } = event.target.dataset;
    const selectInput = this.readibillingInputTargets.find((select) => (
      select.dataset.readibillingId === readibillingId
    ));

    if (!selectInput) return;
    for (let i = 0; i < selectInput.options.length; i++) {
      selectInput.options[i].selected = true;
    }

    selectInput.dispatchEvent(new CustomEvent('chosen:updated'));
  }
}
