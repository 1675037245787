import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['role', 'organization', 'venue'];

  connect() {
    this.toggleCountyAndVenues();
  }

  toggleCountyAndVenues() {
    if (this.hasRoleTarget) {
      const selectedRole = $(this.roleTarget).val();
      const shouldDisplay = $.inArray(selectedRole, ['regional_admin', 'local_admin']) === -1;

      $(this.organizationTarget).toggle(shouldDisplay);
      $(this.venueTarget).toggle(shouldDisplay);
    }
  }
}
