import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form'];

  connect() {
    setTimeout(() => {
      this.formTarget.checkValidity();
    }, 100);
  }
}
