import { Controller } from '@hotwired/stimulus';

// This controller is meant to be used as an outlet in other
// controllers. That's why we expose the setBodyText and
// setButtonText functions so you can set those to whatever
// your needs are.
//
// To use this component's controller as an outlet, in your
// view you should do:
// <div data-controller="test"
//      data-test-simple-message-modal--component-outlet=".simple-message-modal--component">
//   ...
// </div>
//
// And in your controller:
//
// static outlets = ['simple-message-modal--component'];
//
// And one way to access it would be:
// this.simpleMessageModalComponentOutlet.setBodyText();

export default class extends Controller {
  static targets = ['bodyText', 'closeModalButton'];

  static classes = ['hidden'];

  setBodyText(text) {
    this.bodyTextTarget.innerHTML = text;
  }

  setButtonText(text) {
    this.closeModalButtonTarget.text = text;
  }

  showModal() {
    this.element.classList.remove(this.hiddenClass);
  }

  closeModal() {
    this.element.classList.add(this.hiddenClass);
  }

  showButton(forced = false) {
    const hiddenClass = forced ? `!${this.hiddenClass}` : this.hiddenClass;
    this.closeModalButtonTarget.classList.remove(hiddenClass);
  }

  hideButton(forced = false) {
    const hiddenClass = forced ? `!${this.hiddenClass}` : this.hiddenClass;
    this.closeModalButtonTarget.classList.add(hiddenClass);
  }
}
