import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['elementCheckbox', 'selectAllCheckbox'];

  selectAllCheckboxes() {
    const unselected = this.unselectedElements();

    if (unselected.length > 0) {
      this.clickOnElementCheckboxes(unselected);
    } else {
      this.clickOnElementCheckboxes(this.elementCheckboxTargets);
    }

    this.dispatch('allElementsSelected');
  }

  selectedElementsCount() {
    return this.elementCheckboxTargets.filter((checkbox) => checkbox.checked).length;
  }

  unselectedElements() {
    return this.elementCheckboxTargets.filter((checkbox) => checkbox.checked === false);
  }

  static clickOnElementCheckboxes(elementCheckboxes) {
    elementCheckboxes.forEach((entry) => {
      entry.click();
    });
  }

  clickOnElementCheckboxes(elementCheckboxes) {
    this.constructor.clickOnElementCheckboxes(elementCheckboxes);
  }

  checkSelectAllCheckbox() {
    if (this.selectedElementsCount() === this.elementCheckboxTargets.length) {
      this.selectAllCheckboxTarget.checked = true;
      this.dispatch('allElementsSelected');
    } else {
      this.selectAllCheckboxTarget.checked = false;
      this.dispatch('allElementsUnselected');
    }
  }
}
