/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'additionalInfo', 'additionalInfoTextArea', 'eligibleForBoosterLabel', 'eligibleForBoosterRadio', 'warningText',
    'answer', 'notApplicableYesNoModal',
  ];

  answered(event) {
    let needAdditionalInfo = event.currentTarget.value === 'yes' && this.element.dataset.yesDetails === 'true';
    needAdditionalInfo = needAdditionalInfo || (event.currentTarget.value === 'no' && this.element.dataset.noDetails === 'true');

    if (!needAdditionalInfo && this.hasAditionalInfoTextTarget) {
      this.additionalInfoTextAreaTarget.value = null;
    }

    if (needAdditionalInfo) {
      this.additionalInfoTarget.classList.remove('hidden');
      this.additionalInfoTextAreaTarget.required = true;
    } else {
      this.additionalInfoTarget.classList.add('hidden');
      this.additionalInfoTextAreaTarget.required = false;
    }
    if (!(this.element.dataset.enterpriseNotApplicableEnabled === 'true')) {
      this.validateNotApplicable(event.target, this.element.dataset.initialAnswer);
    }
    if (this.hasAditionalInfoTextTarget) {
      this.additionalInfoTextAreaTarget.required = needAdditionalInfo;
    }
  }

  hideOrShowBrandQuestion(event) {
    const vaccineFieldParent = document.getElementById(event.target.id).dataset.parentFieldClass;
    const childField = document.getElementById(event.target.id).dataset.vaccineBrandName;

    if (['second_time', 'booster', 'additional', '1', '3', '4'].includes(event.target.value)) {
      this.hideVaccineFields(vaccineFieldParent, false);
      this.requireChildFields(childField, false);
      this.eligibleForBoosterLabelTargets.forEach((label) => {
        label.classList.add('hidden');
      });
      this.eligibleForBoosterRadioTargets.forEach((radio) => {
        radio.required = false;
      });
    } else if (event.target.value === 'third time' || event.target.value === '2') {
      this.hideVaccineFields(vaccineFieldParent, false);
      this.requireChildFields(childField, true);
      this.eligibleForBoosterLabelTargets.forEach((label) => {
        label.classList.remove('hidden');
      });
      this.eligibleForBoosterRadioTargets.forEach((radio) => {
        radio.required = true;
      });
    } else {
      this.hideVaccineFields(vaccineFieldParent, true);
      this.requireChildFields(childField, false);
      this.eligibleForBoosterLabelTargets.forEach((label) => {
        label.classList.add('hidden');
      });
      this.eligibleForBoosterRadioTargets.forEach((radio) => {
        radio.required = false;
      });
    }
  }

  // Shows not applicable yes no modal used for hardcoded questions.
  showNotApplicableYesNoModal(event) {
    if (this.element.dataset.showNotApplicableYesNoModalEnabled === 'true'
      && event.target.value === 'not_applicable') {
      event.preventDefault();
      this.notApplicableYesNoModalTarget.classList.remove('hidden');
    }
  }

  // After selecting Yes in notApplicableYesNoModal, this method is
  // executed so the not applicable answer for the hardcoded question
  // gets selected.
  acceptChosenAnswer() {
    const notApplicableAnswer = this.answerTargets.find((answer) => answer.value === 'not_applicable');
    if (notApplicableAnswer) notApplicableAnswer.checked = true;
  }

  // After selecting No in notApplicableYesNoModal, this method is
  // executed.
  rejectChosenAnswer() {
    // Do nothing. Do not select the option since user clicked No
  }

  // This function is executed when an answer to a hardcoded question
  // is selected. It calls validateNotApplicable so it shows a warning
  // message shown when the enterprise_not_applicable_hardcoded_question_answer
  // is off but the previous saved answer was not_applicable and the
  // new selected answer is other than not_applicable. This so users
  // are aware that changing and saving this new value won't let them
  // choose again not_applicable.
  answerToHardcodedQuestionAnswered(event) {
    if (!(this.element.dataset.enterpriseNotApplicableHardcodedQuestionAnswerEnabled === 'true')) {
      this.validateNotApplicable(event.target, event.target.dataset.initialAnswer);
    }
  }

  hideVaccineFields(className, hide) {
    Array.from(document.getElementsByClassName(className)).forEach((element) => {
      if (hide) {
        element.classList.add('hidden');
      } else {
        element.classList.remove('hidden');
      }
    });
  }

  requireChildFields(className, required) {
    Array.from(document.getElementsByClassName(className)).forEach((element) => {
      element.required = required;
    });
  }

  // shows a warning message when the initialAnswer was not_applicable and the
  // user selected a different option in target.
  validateNotApplicable(target, initialAnswer) {
    if (!this.hasWarningTextTarget) return;

    if (target.value !== 'not_applicable' && initialAnswer === 'not_applicable') {
      this.warningTextTarget.classList.remove('hidden');
    } else if (!this.warningTextTarget.classList.contains('hidden')) this.warningTextTarget.classList.add('hidden');
  }
}
