import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['flashMessage'];

  connect() {
    if (this.hasFlashMessageTarget) {
      const flashMessage = this.flashMessageTarget;

      flashMessage.classList.add('flash-message-modal-active');
      if ('required' in flashMessage.dataset === false) {
        setTimeout(() => {
          flashMessage.classList.remove('flash-message-modal-active');
          flashMessage.remove();
        }, 20000);
      }
    }
  }

  disconnect() {
    if (this.hasFlashMessageTarget) {
      const flashMessage = this.flashMessageTarget;

      if (flashMessage.classList.contains('flash-message-modal-active')) {
        flashMessage.classList.remove('flash-message-modal-active');
        flashMessage.remove();
      }
    }
  }
}
