import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['firstName', 'lastName', 'dateOfBirth'];

  static values = { defaultToValues: Boolean };

  setDefaultValues(event) {
    if (!this.defaultToValuesValue) return;

    if (event.target.value === 'self') {
      this.firstNameTarget.value = this.firstNameTarget.dataset.defaultValue;
      this.lastNameTarget.value = this.lastNameTarget.dataset.defaultValue;
      this.setDefaultDateOfBirth('month');
      this.setDefaultDateOfBirth('day');
      this.setDefaultDateOfBirth('year');
    }
  }

  setDefaultDateOfBirth(field) {
    const input = this.dateOfBirthTargets.find((el) => el.classList.contains(field));

    if (field === 'month') {
      input.value = input.dataset.defaultMonthValue;
    } else if (field === 'day') {
      input.value = input.dataset.defaultDayValue;
    } else {
      input.value = input.dataset.defaultYearValue;
    }
  }
}
