document.addEventListener('DOMContentLoaded', () => {
  const registrationConsentEnabled = document.querySelector('#clinic_registration_consent_enabled');
  if (registrationConsentEnabled) {
    registrationConsentEnabled.addEventListener('click', () => {
      const isDisabled = registrationConsentEnabled.checked;
      const registrationConsentContent = document.querySelector('.registration_consent_content');
      if (isDisabled) {
        registrationConsentContent.removeAttribute('disabled');
      } else {
        registrationConsentContent.setAttribute('disabled', 'true');
      }
    });
  }
});
