/* eslint-disable import/first */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';

window.Rails = Rails;
Rails.start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('../channels');
require('jquery');
require('../css/application.scss');
require('chartkick');
require('chart.js');
require('awesomplete');
require('./submit_form');
require('./clinic');
require('./scroll');
require('./copy_link');
require('./check_age');
require('./polling');
require('./password_strength');
require('./required');
require('./profile');
require('./consent_for_service');
require('./patients_scroll_to_results');
require('./archive_supply_inventory');
require('./tabs');
require('./trix');
require('./registration_consent_control');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require('./direct_upload');
require('./new_kit');
require('./supply_inventory');
require('./delete_supply_inventory');

import '../controllers';
import 'chosen-js';
import 'chosen-js/chosen.css';
import '@fortawesome/fontawesome-free/js/all';
import '@fortawesome/fontawesome-free/css/all.css';
import moment from 'moment';
import '@nathanvda/cocoon';
import 'flowbite';
import 'jquery-mask-plugin';

require('./chosen_select');
require('./bind_select2');

window.moment = moment;
