import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import yesno from '../packs/yesno-dialog';

export default class extends Controller {
  static targets = ['clinicStartTime', 'clinicStartMeridiem', 'clinicEndTime', 'clinicEndMeridiem',
    'clinicBreakStartTime', 'clinicBreakStartMeridiem', 'clinicBreakEndTime', 'clinicBreakEndMeridiem',
    'clinicBreakForceWithAppointments'];

  connect() {
    this.validateClinicBreaks();
  }

  validateClinicBreaks(event) {
    if (event) {
      const alert = document.getElementById('error-messages');
      alert.classList.add('hidden');
    }

    const clinicStartTime = this.hasClinicStartTimeTarget && this.clinicStartTimeTarget.value;
    const clinicStartMeridiem = this.hasClinicStartMeridiemTarget
      && this.clinicStartMeridiemTarget.value;
    const clinicEndTime = this.hasClinicEndTimeTarget && this.clinicEndTimeTarget.value;
    const clinicEndMeridiem = this.hasClinicEndMeridiemTarget && this.clinicEndMeridiemTarget.value;

    const clinicStartDate = this.buildDate(clinicStartTime, clinicStartMeridiem);
    const clinicEndDate = this.buildDate(clinicEndTime, clinicEndMeridiem);

    if (clinicStartTime && clinicStartMeridiem && clinicEndTime && clinicEndMeridiem) {
      for (let i = 0; i < this.clinicBreakStartTimeTargets.length; i++) {
        this.clearErrors(i);

        if (!this.deletedBreak(i) && !this.isBreakMock(i)) {
          this.validateBreak(clinicStartDate, clinicEndDate, i);
        }
      }

      if (event) {
        this.validateAppointmentsDuringBreak(event);
      }
    }
  }

  validateNewBreakOnEdit() {
    setTimeout(() => {
      this.validateClinicBreaks();
    }, 100);
  }

  deletedBreak(index) {
    const breakContainer = this.clinicBreakEndTimeTargets[index].closest('.clinic-break');
    return breakContainer && breakContainer.querySelector('.destroyed-element').value === '1';
  }

  isBreakMock(index) {
    return !!this.clinicBreakStartTimeTargets[index].closest('.add-another-break-mock');
  }

  validateBreak(clinicStartDate, clinicEndDate, index) {
    const [startDate, endDate] = this.fetchBreakDates(index);

    if (startDate && endDate) {
      if (startDate >= endDate || startDate <= clinicStartDate || endDate >= clinicEndDate) {
        this.addErrors(index);
      }

      this.validateBreaksOverlap(startDate, endDate, index);
    } else {
      this.addErrorsPerField(index);
    }
  }

  validateBreaksOverlap(startDate, endDate, index) {
    for (let i = 0; i < this.clinicBreakStartTimeTargets.length; i++) {
      if (index !== i && !this.deletedBreak(i) && !this.isBreakMock(i)) {
        const [breakStartDate, breakEndDate] = this.fetchBreakDates(i);
        const hasDates = breakStartDate && breakEndDate;

        if (hasDates && startDate <= breakEndDate && breakStartDate <= endDate) {
          this.addErrors(index);
        }
      }
    }
  }

  fetchBreakDates(index) {
    const [
      startTime,
      startMeridiem,
      endTime,
      endMeridiem,
    ] = this.fetchBreakTargetsValues(index);

    let startDate;
    let endDate;

    if (startTime && startMeridiem && endTime && endMeridiem) {
      startDate = this.buildDate(startTime, startMeridiem);
      endDate = this.buildDate(endTime, endMeridiem);
    }

    return [startDate, endDate];
  }

  fetchBreakTargetsValues(index) {
    return [
      this.clinicBreakStartTimeTargets[index].value,
      this.clinicBreakStartMeridiemTargets[index].value,
      this.clinicBreakEndTimeTargets[index].value,
      this.clinicBreakEndMeridiemTargets[index].value,
    ];
  }

  validateAppointmentsDuringBreak(event) {
    const [
      startTimeTarget,
      startMeridiemTarget,
      endTimeTarget,
      endMeridiemTarget,
      forceWithAppointmentsTarget,
    ] = this.getBreakTargetsByIndex(this.findBreakTargetIndex(event.currentTarget));

    if (
      this.shouldValidateBreakWithAppointments(
        startTimeTarget,
        startMeridiemTarget,
        endTimeTarget,
        endMeridiemTarget,
      )
    ) {
      const clinicId = this.data.get('clinic_id');
      const formData = new FormData();

      formData.append('break[start_hour_minute]', startTimeTarget.value);
      formData.append('break[end_hour_minute]', endTimeTarget.value);
      formData.append('break[start_meridiem]', startMeridiemTarget.value);
      formData.append('break[end_meridiem]', endMeridiemTarget.value);
      formData.append('break[clinic_id]', clinicId);

      const that = this;
      Rails.ajax({
        type: 'post',
        url: 'breaks/validate',
        data: formData,
        error: (response) => {
          that.agreeWithError(response.message).then((agreed) => {
            if (agreed) {
              forceWithAppointmentsTarget.value = true;
            } else {
              startTimeTarget.value = '';
              startMeridiemTarget.value = '';
              endTimeTarget.value = '';
              endMeridiemTarget.value = '';
              forceWithAppointmentsTarget.value = false;
              that.validateClinicBreaks();
            }
          });
        },
        success: () => {
          forceWithAppointmentsTarget.value = false;
        },
      });
    }
  }

  shouldValidateBreakWithAppointments(
    startTimeTarget,
    startMeridiemTarget,
    endTimeTarget,
    endMeridiemTarget,
  ) {
    return this.data.get('force_appointment_creation_when_there_are_appointments') === 'true'
      && this.data.get('clinic_id')
      && !startTimeTarget.classList.contains('is-error')
      && !startMeridiemTarget.classList.contains('is-error')
      && !endTimeTarget.classList.contains('is-error')
      && !endMeridiemTarget.classList.contains('is-error');
  }

  // eslint-disable-next-line class-methods-use-this
  buildDate(time, meridiem) {
    // eslint-disable-next-line prefer-const
    let [hour, minute] = time.split(':').map((x) => parseInt(x, 10));

    const date = new Date();
    // avoid incrementing 12 hours to 12:00 PM time.
    if (meridiem === 'PM' && hour !== 12) {
      hour += 12;
    }

    if (meridiem === 'AM' && hour === 12) {
      hour -= 12;
    }

    date.setHours(hour, minute, 0, 0);
    return date;
  }

  addErrorsPerField(index) {
    if (!this.clinicBreakStartTimeTargets[index].value) {
      this.clinicBreakStartTimeTargets[index].classList.add('is-error');
    }
    if (!this.clinicBreakStartMeridiemTargets[index].value) {
      this.clinicBreakStartMeridiemTargets[index].classList.add('is-error');
    }
    if (!this.clinicBreakEndTimeTargets[index].value) {
      this.clinicBreakEndTimeTargets[index].classList.add('is-error');
    }
    if (!this.clinicBreakEndMeridiemTargets[index].value) {
      this.clinicBreakEndMeridiemTargets[index].classList.add('is-error');
    }
  }

  addErrors(index) {
    this.clinicBreakStartTimeTargets[index].classList.add('is-error');
    this.clinicBreakStartMeridiemTargets[index].classList.add('is-error');
    this.clinicBreakEndTimeTargets[index].classList.add('is-error');
    this.clinicBreakEndMeridiemTargets[index].classList.add('is-error');
  }

  clearErrors(index) {
    this.clinicBreakStartTimeTargets[index].classList.remove('is-error');
    this.clinicBreakStartMeridiemTargets[index].classList.remove('is-error');
    this.clinicBreakEndTimeTargets[index].classList.remove('is-error');
    this.clinicBreakEndMeridiemTargets[index].classList.remove('is-error');
  }

  // eslint-disable-next-line class-methods-use-this
  async agreeWithError(message) {
    return yesno({
      labelYes: 'Yes',
      labelNo: 'No',
      bodyText: message,
    });
  }

  getBreakTargetsByIndex(index) {
    return [
      this.clinicBreakStartTimeTargets[index],
      this.clinicBreakStartMeridiemTargets[index],
      this.clinicBreakEndTimeTargets[index],
      this.clinicBreakEndMeridiemTargets[index],
      this.clinicBreakForceWithAppointmentsTargets[index],
    ];
  }

  findBreakTargetIndex(element) {
    let index;

    if (element.dataset.clinicBreaksValidatorTarget === 'clinicBreakStartTime') {
      index = this.clinicBreakStartTimeTargets.findIndex((target) => element === target);
    }

    if (element.dataset.clinicBreaksValidatorTarget === 'clinicBreakEndTime') {
      index = this.clinicBreakEndTimeTargets.findIndex((target) => element === target);
    }

    if (element.dataset.clinicBreaksValidatorTarget === 'clinicBreakStartMeridiem') {
      index = this.clinicBreakStartMeridiemTargets.findIndex((target) => element === target);
    }

    if (element.dataset.clinicBreaksValidatorTarget === 'clinicBreakEndMeridiem') {
      index = this.clinicBreakEndMeridiemTargets.findIndex((target) => element === target);
    }

    return index;
  }
}
