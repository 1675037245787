/* global I18n */

function addInvalidElementListener(requiredEl) {
  requiredEl.addEventListener('invalid', () => {
    let field = requiredEl.getAttribute('aria-label');
    let error;

    try {
      if (!field) {
        const matches = requiredEl.getAttribute('name').match(/(.*\])*(\[(.*)\])/);
        field = matches[matches.length - 1];
      }

      error = I18n.errors.fill_out_field.replace('%{field}', field.replace(/_id/g, '').replace(/_/g, ' '));
    } catch {
      error = I18n.errors.fill_out_default_field;
    }

    requiredEl.setCustomValidity(error);
    if (!requiredEl.classList.contains('is-error')) {
      requiredEl.classList.add('is-error');
    }
  }, false);
}
function highlightInvalid() {
  const requiredExceptRadioAndCheckbox = ":not([type='checkbox']):not([type='radio']):required";
  const requiredElements = document.querySelectorAll(requiredExceptRadioAndCheckbox);

  requiredElements.forEach((requiredEl) => {
    addInvalidElementListener(requiredEl);
    requiredEl.addEventListener('change', () => {
      requiredEl.setCustomValidity('');
      requiredEl.classList.remove('is-error');
    }, false);
  });
}

$(document).ready(() => {
  highlightInvalid();
});
