import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['insuranceCompanyField', 'otherInsuranceField', 'otherInsuranceContainer'];

  static values = { patientId: String };

  connect() {
    this.bindSelect2();
    this.handleInsuranceCompanyChange(this.insuranceCompanyFieldTarget.value);
  }

  bindSelect2() {
    $(this.insuranceCompanyFieldTarget).select2({
      placeholder: 'Start typing to search for an insurance company',
      width: '100%',
      ajax: {
        url: `/insurance_companies?patient_id=${this.patientIdValue}`,
        cache: true,
        dataType: 'json',
        data: (params) => ({
          term: params.term,
          page: params.page || 1,
        }),
        processResults: (data, params) => {
          // eslint-disable-next-line no-param-reassign
          params.page = data.pagination.current_page || 1;
          return {
            results: $.map(data.companies, (company) => ({
              text: company.name,
              id: company.name,
            })),
            pagination: {
              more: params.page < data.pagination.total_pages,
            },
          };
        },
      },
    });

    $(this.insuranceCompanyFieldTarget).on('select2:select', (e) => {
      this.handleInsuranceCompanyChange(e.target.value);
      // Fire event so the change event is catched by Stimulus.
      // For example, we want to clear errors for this field when the user selects an insurance
      // company. And for some reason the change event doesn't fire which causes the issue of
      // the field still showing errors after the user selects an insurance company.
      this.insuranceCompanyFieldTarget.dispatchEvent(new Event('change'));
    });
  }

  handleInsuranceCompanyChange(value) {
    if (value === 'OTHER (PLEASE SPECIFY):') {
      this.addRemoveRequiredFromOtherInsurance(true);
      this.otherInsuranceContainerTarget.classList.remove('hidden');
    } else {
      this.addRemoveRequiredFromOtherInsurance(false);
      this.otherInsuranceContainerTarget.classList.add('hidden');
    }
  }

  addRemoveRequiredFromOtherInsurance(required) {
    if (this.otherInsuranceFieldTarget.dataset.required === 'true') {
      this.otherInsuranceFieldTarget.required = required;
    }
  }
}
