import FieldGroupController from './field_group_controller';

export default class extends FieldGroupController {
  cleanErrors() {
    const isInputInvalid = this.element.classList.contains('is-error');
    const hideError = isInputInvalid && this.errorTarget;

    if (hideError) {
      this.errorTarget.classList.add('hidden');
      this.element.classList.remove('is-error');
    }
  }

  addError() {
    this.createErrorElement();
    this.element.classList.add('is-error');
    this.addErrorText();
    this.errorTarget.classList.remove('hidden');
  }

  createErrorElement() {
    if (!this.hasErrorTarget) {
      const errorElement = document.createElement('div');
      errorElement.classList.add('text-red-600', '-mt-2', 'text-xs', 'mb-4');
      errorElement.setAttribute('data-fieldset-group-target', 'error');
      this.element.querySelector('.js-fieldset-group-container').after(errorElement);
    }
  }
}
