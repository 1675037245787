import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal', 'submit'];

  connect() {
    document.getElementById('skip-email-verification-yes').addEventListener(
      'click',
      this.skipVerification.bind(this),
    );
    document.getElementById('skip-email-verification-no').addEventListener(
      'click',
      this.closeModal.bind(this),
    );
  }

  openModal(event) {
    event.preventDefault();
    this.modalTarget.classList.remove('hidden');
  }

  closeModal(event) {
    event.preventDefault();
    document.getElementById('on-boarding-form').noValidate = false;
    this.modalTarget.classList.add('hidden');
  }

  skipVerification(event) {
    event.preventDefault();
    document.getElementById('next_step').value = 'personal_information';
    document.getElementById('on-boarding-form').noValidate = true;
    this.submitTarget.click();
  }
}
