/* eslint-disable no-use-before-define */
/* eslint-disable no-alert */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'parent', 'inventory', 'ids', 'addSupplyInventoryBtn', 'expiredModal', 'confirmModal', 'expirationLink',
    'newInventory', 'selectedOrg', 'selectedVenue', 'allowExpiredModal',
  ];

  static values = { expiredInventoryAllowed: { type: Boolean, default: false } };

  connect() {
    this.update();
    this.bindChosen();
  }

  bindChosen() {
    $(this.parentTarget).chosen().change(chosenChange);
    $(this.inventoryTarget).chosen().on('change', chosenChange);
  }

  update() {
    const id = $(this.parentTarget).val();
    const inventory = this.inventoryTarget;
    $(inventory).html('');

    if (!id) { return; }

    $.ajax({
      url: `/counties/${id}/supply_inventories`,
      method: 'GET',
      success: (options) => {
        let newOptions = '<option></option>';
        $.each(options, (e) => {
          newOptions += `<option value="${options[e][1]}">${options[e][0]}</option>`;
        });
        $(inventory).html(newOptions);
        $(inventory).trigger('chosen:updated');
      },
    });
  }

  change() {
    this.expiredInventoryAllowedValue = false;
    const id = parseInt($(this.inventoryTarget).val(), 10);

    if (id) {
      $.ajax({
        url: `/supply_inventories/${id}`,
        method: 'GET',
        dataType: 'script',
      });
    }
  }

  hideModal() {
    this.confirmModalTarget.classList.add('hidden');
  }

  hideExpiredModal() {
    this.expiredModalTarget.classList.add('hidden');
  }

  hideAllowExpiredModal() {
    this.allowExpiredModalTarget.classList.add('hidden');
  }

  allowExperiedInventory() {
    this.expiredInventoryAllowedValue = true;
    this.hideAllowExpiredModal();
    this.addSupplyInventory();
  }

  addInventory() {
    const id = parseInt($(this.inventoryTarget).val(), 10);
    const clinicId = $(this.addSupplyInventoryBtnTarget).data('clinicId');

    $.ajax({
      url: `/supply_inventories/${id}/add_to_clinic?clinic_id=${clinicId}`,
      method: 'GET',
      dataType: 'script',
    });
    this.confirmModalTarget.classList.add('hidden');
  }

  addSupplyInventory() {
    const id = parseInt($(this.inventoryTarget).val(), 10);
    const clinicId = $(this.addSupplyInventoryBtnTarget).data('clinicId');

    if (itemAddedAlready(id)) {
      alert("You've already added this item to the clinic.");
      return;
    }

    if (id) {
      if (this.hasExpiredModalTarget) {
        if (this.hasInventoryExpired()) {
          this.showExpirationModal(this.expiredModalTarget);
          return;
        }
      }

      if (this.hasAllowExpiredModalTarget) {
        if (this.hasInventoryExpired() && !this.expiredInventoryAllowedValue) {
          this.showExpirationModal(this.allowExpiredModalTarget);
          return;
        }
      }

      if (this.newInventoryTarget.dataset.venueId !== '' && this.newInventoryTarget.dataset.venueId !== this.selectedVenueTarget.value) {
        this.confirmModalTarget.classList.remove('hidden');
        return;
      }

      if (this.newInventoryTarget.dataset.orgId !== '' && this.newInventoryTarget.dataset.orgId !== this.selectedOrgTarget.value) {
        this.confirmModalTarget.classList.remove('hidden');
        return;
      }

      $.ajax({
        url: `/supply_inventories/${id}/add_to_clinic?clinic_id=${clinicId}`,
        method: 'GET',
        dataType: 'script',
      });
    }
  }

  hasInventoryExpired() {
    const expirationDate = new Date(this.newInventoryTarget.dataset.expirationDate);
    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));

    return expirationDate < yesterday;
  }

  showExpirationModal(modalTarget) {
    modalTarget.classList.remove('hidden');
    this.expirationLinkTarget.href = this.newInventoryTarget.dataset.expirationUrl;
  }

  // eslint-disable-next-line class-methods-use-this
  removeItem(event) {
    $(event.currentTarget).closest('tr').remove();
  }
}

function chosenChange(e) {
  if (e.bubbles) {
    e.preventDefault();
    return;
  }

  const event = new Event('change', { bubbles: true });
  this.dispatchEvent(event);
}

function itemAddedAlready(supplyInventoryId) {
  const existingSupplyInventoryIds = [];

  const inventoryAllocations = $('#clinic_allocated_inventories').children('tr');
  inventoryAllocations.each((_, row) => {
    existingSupplyInventoryIds.push(($(row).data('id')));
  });

  return $.inArray(parseInt(supplyInventoryId, 10), existingSupplyInventoryIds) >= 0;
}
