/* global I18n */
import { Controller } from '@hotwired/stimulus';

const moment = require('moment');

export default class extends Controller {
  static targets = ['onWaitingList', 'appointmentAt', 'timeSlotRow', 'confirmationModal', 'onboardingForm', 'save'];

  validateWaitingList() {
    this.onWaitingListTarget.removeAttribute('required');
    this.onWaitingListTarget.checked = false;

    this.appointmentAtTargets.forEach((element) => {
      element.setAttribute('required', true);
    });
  }

  validateAppointmentTimeInputs() {
    this.appointmentAtTargets.forEach((element) => {
      element.removeAttribute('required');
      // eslint-disable-next-line no-param-reassign
      element.checked = false;
    });

    this.onWaitingListTarget.setAttribute('required', true);
  }

  selectRadioBtn(event) {
    const clickedElement = event.currentTarget;
    $(clickedElement).find('.appointment-radio-btn').prop('checked', true);
    this.scrollToThePageBottom();
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToThePageBottom() {
    setTimeout(() => {
      $('html, body').animate({ scrollTop: $(document).height() }, 1000);
    }, 1500);
  }

  fetchSlots(event) {
    const el = event.currentTarget;
    const parentDiv = $('.appointments-table');
    $('.cursor-pointer').removeClass('bg-gray-200');
    $(`#${el.id}`).addClass('bg-gray-200');
    $(`#${el.id}`).removeClass('bg-white');

    if (el.dataset.clicked) {
      const firstChild = $(`[data-parent="${$(el).attr('id')}"]`);
      const childRow = $(firstChild).find('[data-clicked="true"]');
      const secondChild = $(`[data-parent="${$(childRow).attr('id')}"]`);

      $('.cursor-pointer').removeClass('bg-gray-200');
      $(`#${el.id}`).addClass('bg-white');

      secondChild.remove();
      firstChild.remove();
      $(el).removeAttr('data-clicked');
      $(childRow).removeAttr('data-clicked');
      // eslint-disable-next-line no-restricted-globals
      $(parent).removeAttr('data-clicked');
      if (el.dataset.parentId) {
        $(`#${el.dataset.parentId}`).addClass('bg-gray-200');
      }

      return;
    }

    if (el.dataset.parentId) {
      $(`#${el.dataset.parentId}`).addClass('bg-gray-200');
    }

    if (parentDiv.attr('data-clicked') && parentDiv.attr('data-clicked-row') !== el.dataset.parentId) {
      const childSections = $('.child-section');
      [...childSections].forEach((ele) => {
        if ($(ele).attr('data-parent') !== el.dataset.parentId) {
          $(ele).remove();
        }
      });

      $(this.timeSlotRowTargets).removeAttr('data-clicked');
    }

    const { clinicId } = el.dataset;
    const { startAt } = el.dataset;
    const { endAt } = el.dataset;

    $.ajax({
      url: `/registration/appointment/slots/${clinicId}/${startAt}/${endAt}`,
      method: 'GET',
      dataType: 'script',
      beforeSend: () => {
        $(el).find('.fa-spinner').removeClass('hidden');
      },
    });

    document.getElementById('scrollRef').scrollIntoView();
  }

  fetchNewSlots(event) {
    const el = event.currentTarget;
    const parentDiv = $(el.closest('.appointments-table'));
    const patientIndex = parentDiv.attr('data-patient-index');
    const patientId = parentDiv.attr('data-patient-id');
    $(parentDiv).find('.cursor-pointer').removeClass('bg-gray-200');
    $(`#${el.id}`).addClass('bg-gray-200');
    $(`#${el.id}`).removeClass('bg-white');

    if (el.dataset.clicked) {
      const firstChild = $(`[data-parent="${$(el).attr('id')}"]`);
      const childRow = $(firstChild).find('[data-clicked="true"]');
      const secondChild = $(`[data-parent="${$(childRow).attr('id')}"]`);

      $(parentDiv).find('.cursor-pointer').removeClass('bg-gray-200');
      $(`#${el.id}`).addClass('bg-white');

      secondChild.remove();
      firstChild.remove();
      $(el).removeAttr('data-clicked');
      $(childRow).removeAttr('data-clicked');
      // eslint-disable-next-line no-restricted-globals
      $(parent).removeAttr('data-clicked');
      if (el.dataset.parentId) {
        $(`#${el.dataset.parentId}`).addClass('bg-gray-200');
      }

      return;
    }

    if (el.dataset.parentId) {
      $(`#${el.dataset.parentId}`).addClass('bg-gray-200');
    }

    if (parentDiv.attr('data-clicked') && parentDiv.attr('data-clicked-row') !== el.dataset.parentId) {
      const childSections = $(`.child-section-${patientIndex}`);
      [...childSections].forEach((ele) => {
        if ($(ele).attr('data-parent') !== el.dataset.parentId) {
          $(ele).remove();
        }
      });

      $(this.timeSlotRowTargets).removeAttr('data-clicked');
    }

    const { clinicId } = el.dataset;
    const { startAt } = el.dataset;
    const { endAt } = el.dataset;
    const urlPath = `${clinicId}/${startAt}/${endAt}/${patientId}/${patientIndex}`;

    $.ajax({
      url: `/registration/appointment/slots/${urlPath}`,
      method: 'GET',
      dataType: 'script',
      beforeSend: () => {
        $(el).find('.fa-spinner').removeClass('hidden');
      },
    });

    document.getElementById(`scrollRef-${patientIndex}`).scrollIntoView();
  }

  toggleConfirmModal(event) {
    if (this.data.get('step') === 'appointment') {
      if (parseInt(this.data.get('availableAppointmentsCount'), 10) > 0 && $("input[name='appointment[appointment_at]']:checked").val() === 'waiting') {
        event.preventDefault();
        this.confirmationModalTarget.classList.toggle('hidden');
      }
    }
  }

  submitOnboardingForm() {
    this.onboardingFormTarget.submit();
  }

  // eslint-disable-next-line class-methods-use-this
  closeAgeModal() {
    $('#age-check-modal').addClass('hidden');
  }

  // eslint-disable-next-line class-methods-use-this
  checkFields(event) {
    const invalidElements = document.getElementsByClassName('invalid-field');
    if (invalidElements.length !== 0) {
      invalidElements[0].focus();
      event.preventDefault();
    }
  }

  verifyAge(event) {
    const { saveTarget } = this;
    const vaccineMinAgeInMonths = saveTarget.dataset.vaccineMinAgeInMonths
      && parseInt(saveTarget.dataset.vaccineMinAgeInMonths, 10);
    const clinicCheckAge = saveTarget.dataset.clinicCheckAge === 'true';
    const clinicAcceptedMinAgeInMonths = saveTarget.dataset.clinicAcceptedMinAgeInMonths
      && parseInt(saveTarget.dataset.clinicAcceptedMinAgeInMonths, 10);
    const clinicAcceptedMaxAgeInMonths = saveTarget.dataset.clinicAcceptedMaxAgeInMonths
      && parseInt(saveTarget.dataset.clinicAcceptedMaxAgeInMonths, 10);

    const clinicDateStr = saveTarget.dataset.clinicDate;
    let clinicDate = null;
    let comparisonDate;
    let alertShown = false;

    if (clinicDateStr !== undefined) {
      const [clinicYear, clinicMonth, clinicDay] = clinicDateStr.split('-');
      clinicDate = new Date(clinicYear, clinicMonth - 1, clinicDay);
      comparisonDate = clinicDate;
    } else {
      comparisonDate = new Date();
    }

    $('.date-of-birth, .date-of-birth-confirmation').each((_index, element) => {
      let message = '';
      const monthDayYearValues = [];
      let dateOfBirth = '';

      $(element).find('select').each((_i, e) => { monthDayYearValues.push($(e).val()); });

      if (monthDayYearValues.includes('')) {
        dateOfBirth = '';
      } else {
        const validCrossBrowserDateFormat = monthDayYearValues.join('/');
        dateOfBirth = new Date(validCrossBrowserDateFormat);
      }

      if (dateOfBirth) {
        const ageYearsDiff = moment(comparisonDate).diff(dateOfBirth, 'years', true);
        const ageMonthsDiff = moment(comparisonDate).diff(dateOfBirth, 'months', true);
        const age = parseInt(Math.floor(ageYearsDiff), 10);
        const ageInMonths = parseInt(Math.floor(ageMonthsDiff), 10);
        const invalidAgeInMonths = ageInMonths < clinicAcceptedMinAgeInMonths
          || ageInMonths > clinicAcceptedMaxAgeInMonths;

        const vaccineMinAgeYears = Math.floor(vaccineMinAgeInMonths / 12);
        if (age < 0) {
          message = I18n.errors.dob_in_past;
        } else if (clinicCheckAge && invalidAgeInMonths) {
          $('#age-check-modal').removeClass('hidden');
          event.preventDefault();
        } else if (ageInMonths < vaccineMinAgeInMonths) {
          if (vaccineMinAgeYears === 0) {
            message = I18n.errors.too_young_for_vaccine_in_months.replace('%{age}', vaccineMinAgeInMonths);
          } else if (vaccineMinAgeInMonths % 12 === 0) {
            message = I18n.errors.too_young_for_vaccine_in_years.replace('%{age}', vaccineMinAgeYears);
          } else {
            message = I18n.errors.too_young_for_vaccine.replace('%{years}', vaccineMinAgeYears).replace('%{months}', vaccineMinAgeInMonths % 12);
          }
        }
      }

      if (message && alertShown === false) {
        // eslint-disable-next-line no-alert
        alertShown = true;
        alert(message);
        event.preventDefault();
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  print() {
    window.print();
  }
}
