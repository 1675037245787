/* eslint-disable no-param-reassign */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['sameAsParentTrigger', 'field'];

  connect() {
    // select2 components bind on turbolinks:load. We need to delay the execution
    // of applyValues until after the chosen select components have been bound.
    $(document).on('turbolinks:load', () => {
      this.applyValues();
    });
  }

  applyValues() {
    if (this.sameAsParentTriggerTarget.checked) {
      this.fieldTargets.forEach((element) => {
        element.value = element.dataset.parentField === undefined ? '' : element.dataset.parentField;
        this.updateSelect2(element);
      });
      this.fieldTargets.forEach((element) => {
        element.disabled = true;
        this.updateSelect2(element);
      });
      this.fieldTargets.forEach((element) => $(element).css('background-color', '#e8e8e8'));
      this.fieldTargets.forEach((element) => $(element).css('cursor', 'not-allowed'));
    } else {
      this.fieldTargets.forEach((element) => {
        element.value = element.dataset.selfValue === undefined ? '' : element.dataset.selfValue;
        this.updateSelect2(element);
      });
      this.fieldTargets.forEach((element) => {
        element.disabled = false;
        this.updateSelect2(element);
      });
      this.fieldTargets.forEach((element) => $(element).css('background-color', 'white'));
      this.fieldTargets.forEach((element) => $(element).css('cursor', 'text'));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  updateSelect2(element) {
    // Use dispatchEvenet to fire a change event on the original
    // select field, otherwise, the select2 component does not change.
    // For some reason the way the select2 documentation to fire events
    // does not work.
    if (element.classList.contains('select2-hidden-accessible')) {
      element.dispatchEvent(new Event('change'));
    }
  }
}
