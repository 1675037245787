import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['number'];

  connect() {
    setInterval(() => {
      const tickNumber = parseInt(this.numberTarget.innerHTML.split(',').join(''), 10);
      this.numberTarget.innerHTML = (tickNumber - 13).toLocaleString();
    }, 1000);
  }
}
