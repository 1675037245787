import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['vaccine', 'conflictModal', 'vaccineListItem', 'searchField', 'noResults', 'noResultsQuery'];

  lastSelectedTarget = null;

  onKeyPress(event) {
    const { value: searchCriteria } = event.target;

    if (searchCriteria.length >= 3) {
      let hasResults = false;

      this.vaccineListItemTargets.forEach((listItem) => {
        const {
          vaccineName, vaccineLotNumber, vaccineSource, vaccineFamily,
        } = listItem.dataset;
        const searchData = `${vaccineName}-${vaccineLotNumber}-${vaccineSource}-${vaccineFamily}`;

        const foundResult = searchCriteria.split(' ').every((searchTerm) => {
          const match = searchData.match(new RegExp(searchTerm, 'i'));

          if (match) {
            listItem.classList.remove('hidden');
          } else {
            listItem.classList.add('hidden');
          }

          return match;
        });

        if (foundResult) hasResults = true;
      });

      if (hasResults) {
        this.noResultsTarget.classList.add('hidden');
      } else {
        this.noResultsTarget.classList.remove('hidden');
        this.noResultsQueryTarget.innerText = searchCriteria;
      }
    } else {
      this.vaccineListItemTargets.forEach((listItem) => {
        this.noResultsTarget.classList.add('hidden');
        listItem.classList.remove('hidden');
      });
    }
  }

  onVaccineClick(event) {
    if (this.featureEnabled()) {
      const element = event.target;
      this.lastSelectedTarget = element;
      if (element.checked && this.conflictsWithAnotherVaccine(element)) {
        this.conflictModalTarget.classList.toggle('hidden');
      }
    }
  }

  conflictsWithAnotherVaccine(vaccine) {
    const relatedCvxCodes = JSON.parse(vaccine.dataset.relatedCvxCodes);
    const withConflicts = this.selectedVaccines(vaccine)
      .filter((selectedVaccine) => relatedCvxCodes.includes(selectedVaccine.dataset.cvxCode));

    return withConflicts.length > 0;
  }

  selectedVaccines(toExclude) {
    return this.vaccineTargets.filter((vaccine) => vaccine.checked && vaccine !== toExclude);
  }

  closeModal() {
    this.conflictModalTarget.classList.toggle('hidden');
  }

  cancelSelection() {
    this.lastSelectedTarget.click();
    this.closeModal();
  }

  featureEnabled() {
    return this.element.dataset.alertusersonsamevaccinefamilyinpatientencounterbox === 'true';
  }
}
