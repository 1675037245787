/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-unused-vars
/* global bindOtherInsurance */
/* global Turbolinks */

import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';

function addRemoveRequiredFromOtherInsurance(target, required) {
  if (target.data('required') === true) {
    target.prop('required', required);
  }
}

window.bindOtherInsurance = (value) => {
  const target = $('.other-insurance-field');

  if (value === 'OTHER (PLEASE SPECIFY):') {
    addRemoveRequiredFromOtherInsurance(target, true);
    $('#other-secondary-insurance').show();
  } else {
    addRemoveRequiredFromOtherInsurance(target, false);
    $('#other-secondary-insurance').hide();
  }
};

function bindVaccinatorNameSelect2() {
  const select2Element = $('.clinic-staff-name');
  if (select2Element.length > 0) {
    $('.clinic-staff-name').select2({
      width: '100%',
    });
  }
}

function messageClinicOptions() {
  $('#select2-clinics').select2({
    tokenSeperators: [','],
    placeholder: ' ',
    multiple: true,
    closeOnSelect: false,
    allowHtml: true,
    allowClear: true,
    ajax: {
      url: '/clinics',
      dataType: 'json',
      multiple: true,
      data: (params) => ({
        term: params.term,
        page_limit: 100,
        page: params.page || 1,
        order: $('#select2-clinics').data('order'),
      }),
      processResults: (data, params) => {
        params.page = data.page || 1;
        return {
          results: $.map(data.items, (item) => ({
            text: item.name,
            id: item.id,
          })),
          pagination: {
            more: (params.page * 10) < data.total_count,
          },
        };
      },
      cache: false,
    },
  });
}

function selectClinicOptions() {
  $('#clinics-select2').select2({
    placeholder: ' ',
    allowHtml: true,
    allowClear: true,
    ajax: {
      url: '/clinics',
      dataType: 'json',
      multiple: true,
      data: (params) => ({
        term: params.term,
        page_limit: 100,
        page: params.page || 1,
        open_for_registration: true,
        with_open_registrations: true,
      }),
      processResults: (data, params) => {
        params.page = data.page || 1;
        return {
          results: $.map(data.items, (item) => ({
            text: item.name,
            id: item.id,
          })),
          pagination: {
            more: (params.page * 10) < data.total_count,
          },
        };
      },
      cache: false,
    },
  });

  setDefaultSelectClinicOption();
}

function setDefaultSelectClinicOption() {
  const data = $('#clinics-select2').data();

  if (data?.preselected) {
    const option = new Option(data.title, data.id, true, true);
    $('#clinics-select2').append(option).trigger('change');
  }
}

function bindSelectClinicOptions() {
  $('#clinics-select2').on('select2:select', (e) => {
    $('#submitButton').attr('disabled', true);
    const url = new URL(window.location);
    const { searchParams } = url;
    searchParams.set('clinic_id', $(e.target).val());
    searchParams.set('preselected', true);

    Turbolinks.visit(url);
  });
}

function destroySelectClinicOptions() {
  $('#clinics-select2').select2('destroy');
}

function inventoryVaccineOptions() {
  const element = $('#vaccines-select2');
  element.select2({
    tokenSeperators: [','],
    placeholder: 'Vaccine Name',
    ajax: {
      url: '/vaccines/dropdown_options',
      dataType: 'json',
      data: (params) => ({
        term: params.term,
      }),
      processResults: (data) => ({
        results: $.map(data, (obj) => ({ id: obj.id, text: obj.name })),
      }),
      cache: false,
    },
  });

  bindSelect2Change(element);
}

function preSelectVaccineOption() {
  if ($('#vaccines-select2 option:selected').length === 0 || $('#vaccines-select2 option:selected').val() === '') return;

  const vaccineId = $('#vaccines-select2 option:selected').val();
  $.ajax({
    type: 'GET',
    url: `/vaccines/${vaccineId}/fetch_data/?as_option=true`,
  }).then((data) => {
    const option = new Option(data.name, data.id, true, true);
    $('#vaccines-select2').append(option).trigger('change');
  });
}

function preSelectManufacturerOption() {
  if ($('#manufacturers-select2 option:selected').length === 0 || $('#manufacturers-select2 option:selected').val() === '') return;

  const manufacturerId = $('#manufacturers-select2 option:selected').val();
  $.ajax({
    type: 'GET',
    url: `/manufacturers/${manufacturerId}/fetch_detail`,
  }).then((data) => {
    const option = new Option(data.name, data.id, true, true);
    $('#manufacturers-select2').append(option).trigger('change');
  });
}

function inventoryManufacturersOptions() {
  const element = $('#manufacturers-select2');
  element.select2({
    tokenSeperators: [','],
    placeholder: 'Manufacturer *',
    ajax: {
      url: '/manufacturers/dropdown_options',
      dataType: 'json',
      data: (params) => ({
        term: params.term,
      }),
      processResults: (data) => ({
        results: $.map(data, (obj) => ({ id: obj.id, text: obj.name })),
      }),
      cache: false,
    },
  });

  bindSelect2Change(element);
}

function bindSelect2Change(element) {
  element.on('select2:select', (e) => {
    const event = new Event('change', { bubbles: true });
    e.target.dispatchEvent(event);
  });
}

$(document).on('turbolinks:load', () => {
  messageClinicOptions();
  selectClinicOptions();
  bindSelectClinicOptions();
  bindVaccinatorNameSelect2();
  inventoryVaccineOptions();
  preSelectVaccineOption();
  inventoryManufacturersOptions();
  preSelectManufacturerOption();
});

$(document).on('turbolinks:before-cache', () => {
  destroySelectClinicOptions();
});
