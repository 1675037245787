import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static outlets = ['simple-message-modal--component'];

  static values = { translations: Object, path: String };

  registerPatient(event) {
    event.preventDefault();
    this.openModal();
    this.generatePatient();
  }

  openModal() {
    this.simpleMessageModalComponentOutlet.setBodyText(this.waitModalText());
    this.simpleMessageModalComponentOutlet.hideButton(true);
    this.simpleMessageModalComponentOutlet.showModal();
  }

  generatePatient() {
    Rails.ajax({
      url: this.pathValue,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        window.location.href = `/clinics/${data.clinic_id}/register?registrant_code=${data.encrypted_patient_id}`;
      },
      error: () => {
        this.simpleMessageModalComponentOutlet.setBodyText(this.errorModalText());
        this.simpleMessageModalComponentOutlet.showButton(true);
      },
    });
  }

  waitModalText() {
    return '<div class="text-center">'
             + `<i class="fas fa-spinner fa-spin text-5xl mr-5"></i><p>${this.translationsValue.wait_while_processing_patient}</p>`
           + '</div>';
  }

  errorModalText() {
    return '<div class="text-center">'
             + `<p>${this.translationsValue.something_went_wrong}</p>`
           + '</div>';
  }
}
