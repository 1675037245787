/* global bindOtherInsurance */

import { Controller } from '@hotwired/stimulus';
import VMasker from 'vanilla-masker';

export default class extends Controller {
  static targets = ['insuranceContainer', 'otherCompany', 'ssnInput'];

  connect() {
    if (this.hasOtherCompanyTarget) {
      bindOtherInsurance(this.otherCompanyTarget.value);
    }

    if (this.hasSsnInputTarget) {
      this.maskSsn();
    }
  }

  handleRadioButtonChange(event) {
    if (event.target.value === 'Yes') {
      this.insuranceContainerTarget.remove();
    } else {
      $.ajax({
        url: '/registration/new_client/registrations/toggle_same_insurance',
        method: 'GET',
        dataType: 'script',
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleCompanySelectChange(event) {
    bindOtherInsurance(event.target.value);
  }

  toggleSsnVisibility(event) {
    if (!this.hasSsnInputTarget) return;

    if (event.target.value === 'Medicare') {
      this.ssnInputTarget.querySelector('input').disabled = false;
      this.ssnInputTarget.classList.remove('hidden');
    } else {
      this.ssnInputTarget.querySelector('input').disabled = true;
      this.ssnInputTarget.classList.add('hidden');
    }
  }

  maskSsn() {
    VMasker(this.ssnInputTarget.querySelector('input')).maskPattern('999-99-9999');
  }
}
