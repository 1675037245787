/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */

import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
  static values = { selectedItems: Array };

  static targets = [
    'selectedValuesInput',
    'selectedItemsContainer',
    'selectedItem',
    'noSelectedItemsText',
    'availableItemsContainer',
    'availableItem',
    'noAvailableItemsText',
    'searchInput',
    'searchButton',
    'searchCount',
  ];

  connect() {
    this.sortable = new Sortable(this.selectedItemsContainerTarget, {
      onEnd: () => this.saveOrder(),
      dataIdAttr: 'id',
      handle: '.fa-bars',
    });

    this.sortableInput = new Sortable(this.selectedValuesInputTarget, {
      onEnd: () => this.saveOrder(),
      dataIdAttr: 'value',
    });

    const { options } = this.selectedValuesInputTarget;
    for (let i = 0; i < options.length; i++) {
      options.item(i).id = options.item(i).value;
    }

    const initialItems = this.selectedItemTargets;
    this.selectedItemsValue = initialItems.map((option) => {
      const { value, text } = this.fetchItemData(option.lastElementChild.firstElementChild);
      option.remove();

      return `${value}|${text}`;
    });

    setTimeout(() => {
      this.saveOrder();
    }, 100);
  }

  addAll() {
    this.availableItemTargets.forEach((option) => {
      const { value, text } = this.fetchItemData(option.lastElementChild.firstElementChild);

      if (!option.className.match('hidden')) {
        this.selectedItemsValue = this.selectedItemsValue.concat(`${value}|${text}`);
      }
    });
  }

  removeAll() {
    const optionsToRemove = this.selectedItemTargets.map(
      (option) => this.fetchItemData(option.lastElementChild.firstElementChild).value,
    );

    this.searchCountTarget.innerText = '';
    this.selectedItemsValue = this.selectedItemsValue.filter(
      (option) => optionsToRemove.includes(option),
    );
  }

  addItem(event) {
    const { value, text } = this.fetchItemData(event.target);

    this.selectedItemsValue = this.selectedItemsValue.concat(`${value}|${text}`);
  }

  removeItem(event) {
    const { value, text } = this.fetchItemData(event.target);

    this.selectedItemsValue = this.selectedItemsValue.filter(
      (selectedValue) => selectedValue !== `${value}|${text}`,
    );
  }

  selectedItemsValueChanged(newValue, previousValue) {
    if (!previousValue) return;
    if (newValue.length === previousValue.length) return;
    if (newValue.length !== new Set(newValue).size) return;

    if (newValue.length > previousValue.length) {
      const addedValues = newValue.filter((value) => !previousValue.includes(value));

      addedValues.forEach((addedValue) => {
        const [value, text] = addedValue.split('|');

        this.selectedItemsContainerTarget.innerHTML += this.selectedItemHtml(text, value);
        this.setOption(value, true);
        document.getElementById(`option---${value}`).remove();
      });
    } else {
      const deletedValues = previousValue.filter((item) => !newValue.includes(item));

      deletedValues.forEach((deletedValue) => {
        const [value, text] = deletedValue.split('|');

        this.availableItemsContainerTarget.innerHTML += this.selectableItemHtml(text, value);
        this.setOption(value, false);
        document.getElementById(`choice---${value}`).remove();
      });
    }

    this.saveOrder();
    this.search();
    this.validateEmptyState();
  }

  search(event) {
    event?.preventDefault();

    if (event === undefined || event.keyCode === 13 || event.keyCode === undefined) {
      const searchTerm = this.searchInputTarget.value;

      const matchesCount = this.availableItemTargets.reduce((accumulator, option) => {
        const { text } = this.fetchItemData(option.lastElementChild.firstElementChild);
        const match = !!text.match(new RegExp(searchTerm, 'i'));

        if (match) {
          option.classList.remove('hidden');
        } else {
          option.classList.add('hidden');
        }

        return accumulator + match;
      }, 0);

      if (searchTerm) {
        this.searchCountTarget.innerText = `${matchesCount} options found for "${searchTerm}"`;
      } else {
        this.searchCountTarget.innerText = '';
      }
    }

    return false;
  }

  sort() {
    this.sortable.sort(this.sortable.toArray().sort(), false);
    this.saveOrder();
  }

  saveOrder() {
    const order = this.sortable.toArray().map((v) => v.split('---')[1]);
    this.sortableInput.sort(order, false);
  }

  optionsCount() {
    return this.selectedItemTargets.length + this.availableItemTargets.length;
  }

  validateEmptyState() {
    const selectedItems = this.selectedItemTargets.length;

    if (selectedItems === this.optionsCount()) {
      this.noAvailableItemsTextTarget.classList.remove('hidden');
    } else {
      this.noAvailableItemsTextTarget.classList.add('hidden');
    }

    if (selectedItems === 0) {
      this.noSelectedItemsTextTarget.classList.remove('hidden');
    } else {
      this.noSelectedItemsTextTarget.classList.add('hidden');
    }
  }

  setOption(option, value) {
    this.selectedValuesInputTarget.options.namedItem(option).selected = value;
  }

  fetchItemData(target) {
    const element = target.tagName === 'path' ? target.parentElement : target;

    const value = element.parentElement.parentElement.id.split('---')[1];
    const text = element.parentElement.previousElementSibling.innerText;

    return { value, text };
  }

  selectableItemHtml(text, value) {
    /* eslint-disable max-len */
    return `
      <li class="p-4 bg-white border-solid border-1 border-gray-400 border-b flex justify-between items-center" id="option---${value}" data-smart-select-target="availableItem">
        <span>${text}</span>
        <button type="button" data-action="smart-select#addItem">
          <i class="fas fa-plus text-xl text-blue-700 border-solid border-2 border-blue-700 rounded-full h-6 p-1" style="width: 1.5rem" data-action="smart-select#addItem"></i>
        </button>
      </li>
    `;
    /* eslint-enable max-len */
  }

  selectedItemHtml(text, value) {
    /* eslint-disable max-len */
    return `
      <li class="p-4 bg-white border-solid border-1 border-gray-400 border-b flex items-center" id="choice---${value}" data-smart-select-target="selectedItem">
        <i class="px-1 fas fa-bars text-xl text-gray-500 h-6 mr-3" style="width: 1.5rem"></i>
        <span class="mr-auto">${text}</span>
        <button type="button" data-action="smart-select#removeItem">
          <i class="fas fa-minus text-xl text-blue-700 border-solid border-2 border-blue-700 rounded-full h-6 p-1" style="width: 1.5rem"></i>
        </button>
      </li>
    `;
    /* eslint-enable max-len */
  }
}
