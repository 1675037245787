import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dayInput', 'monthInput', 'yearInput', 'dateBlock'];

  submit(event) {
    const dayFilled = this.hasDayInputTarget && this.dayInputTarget.value;
    const monthFilled = this.hasMonthInputTarget && this.monthInputTarget.value;
    const yearFilled = this.hasYearInputTarget && this.yearInputTarget.value;

    const someDateFilled = dayFilled || monthFilled || yearFilled;
    const allDateFilled = dayFilled && monthFilled && yearFilled;

    if (someDateFilled && (!allDateFilled)) {
      this.highlightDate();
      event.preventDefault();
    }
  }

  highlightDate() {
    if (this.hasDateBlockTarget) {
      const searchByDateBlock = this.dateBlockTarget;
      searchByDateBlock.classList.add('red-outline');
      setTimeout(() => {
        searchByDateBlock.classList.remove('red-outline');
      }, 1000);
    }
  }
}
