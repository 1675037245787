import { Controller } from '@hotwired/stimulus';

/*  eslint-disable no-lonely-if */

export default class extends Controller {
  static targets = [
    'searchableRow',
    'daysBetweenDoses',
    'numberOfDoses',
    'submit',
    'itemTypeCheckbox',
  ];

  connect() {
    this.toggleDaysBetweenDoses();
  }

  search(event) {
    const searchTerm = event.target.value.toLowerCase();
    this.searchableRowTargets.forEach((el) => {
      const filterable = el.getAttribute('data-search-content');

      el.classList.toggle('hidden', !filterable.includes(searchTerm));
    });
  }

  toggleDaysBetweenDoses() {
    const daysBetweenDoses = this.hasDaysBetweenDosesTarget ? this.daysBetweenDosesTarget : null;

    if (daysBetweenDoses) {
      const numberOfDoses = this.hasNumberOfDosesTarget ? this.numberOfDosesTarget : null;

      if (numberOfDoses?.value) {
        if (parseInt(numberOfDoses.value, 10) < 2) {
          daysBetweenDoses.value = 0;
          daysBetweenDoses.setAttribute('readOnly', true);
        } else {
          daysBetweenDoses.removeAttribute('readOnly');
        }
      }
    }
  }

  archive(event) {
    event.preventDefault();
    this.archiveOrUnarchive('archive');
  }

  unarchive(event) {
    event.preventDefault();
    this.archiveOrUnarchive('unarchive');
  }

  archiveOrUnarchive(actionText) {
    if (this.selectedItemsCount() === 0) {
      // eslint-disable-next-line no-restricted-globals
      alert(`Please select at least one item type to ${actionText}.`);
    } else {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to proceed?.')) {
        // The form is the same for archive and unarchive. The code that decided which
        // action the form is going to have is set in the erb file. So, here's safe to
        // just submit the form.
        this.submitTarget.click();
      }
    }
  }

  selectedItemsCount() {
    return this.itemTypeCheckboxTargets.filter((checkbox) => checkbox.checked).length;
  }
}
