/* global I18n */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dobConfirmation', 'dobFields'];

  checkDateOfBirth() {
    const dobConfirmation = this.dobConfirmationTargets;
    const dobFields = this.dobFieldsTargets;

    if (dobConfirmation?.length === 0 || dobFields?.length === 0) {
      return;
    }

    const errorMessage = document.getElementsByClassName('date-confirmation-errors')[0];
    const submitButton = document.getElementById('submitButton');

    if (dobFields[0].value !== dobConfirmation[0].value
      || dobFields[1].value !== dobConfirmation[1].value
      || dobFields[2].value !== dobConfirmation[2].value) {
      errorMessage.innerHTML = I18n.errors.date_of_birth_confirmation;
      submitButton.disabled = true;

      this.dobConfirmationTargets.forEach((e) => {
        e.classList.add('invalid-field');
        this.dispatch('invalidDateOfBirthConfirmation');
      });
    } else {
      errorMessage.innerHTML = '';
      submitButton.disabled = false;

      this.dobConfirmationTargets.forEach((e) => {
        e.classList.remove('invalid-field');
      });
    }
  }
}
