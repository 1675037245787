import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['deletPersonnelButton', 'hiddenDestroyField'];

  static outlets = ['simple-message-modal--component'];

  static classes = ['hidden'];

  deletePersonnel(event) {
    event.preventDefault();
    const clickedButton = event.currentTarget;

    if (clickedButton.dataset.hasVaccinatedPatients === 'true') {
      this.simpleMessageModalComponentOutlet.setBodyText('Staff who have recorded patient vaccination encounters may not be removed.');
      this.simpleMessageModalComponentOutlet.showModal();
    } else {
      // Set hidden to 1 so personnel gets deleted when submitting the form.
      this.hiddenDestroyFieldTarget.value = '1';

      // Hide the personnel container.
      this.element.classList.add(this.hiddenClass);
    }
  }
}
