import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['responsibleSignatureSection', 'signatureImage', 'signatureFirstName',
    'signatureLastName', 'signatureSection', 'clearSignature', 'role'];

  onSelectClinicCreatorMode() {
    this.responsibleSignatureSectionTarget.classList.add('hidden');
    this.signatureSectionTarget.classList.remove('hidden');
    this.signatureImageTarget.value = '';
    this.signatureImageTarget.removeAttribute('required');
    this.roleTarget.dataset.role = 'clinic-creator';
  }

  onSelectOtherUserMode() {
    this.responsibleSignatureSectionTarget.classList.remove('hidden');
    this.signatureImageTarget.setAttribute('required', '');
    this.signatureSectionTarget.classList.add('hidden');
    if (this.hasSignatureFirstNameTarget) {
      this.signatureFirstNameTarget.value = '';
    }
    if (this.hasSignatureLastNameTarget) {
      this.signatureLastNameTarget.value = '';
    }
    this.clearSignatureTarget.click();
    this.roleTarget.dataset.role = 'other';
  }
}
