/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['clinic', 'privateClinic'];

  closeModal() {
    $('#appointment-modal').addClass('hidden');

    const cookieExpiry = new Date();
    cookieExpiry.setTime(cookieExpiry.getTime() + (365 * 24 * 60 * 60 * 1000));
    let cookie = null;
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      cookie = cookies[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1, cookie.length);
      }
    }

    if (cookie != null) {
      // document.cookie = `${cookie.split('=')[0]}=true; expires=${cookieExpiryString}`;
    }
  }

  updateEmailFieldAttributes() {
    const emailRequired = $('#patient_has_email').is(':checked');
    $('#patient_email').prop('required', !emailRequired);
    $('#patient_email_confirmation').prop('required', !emailRequired);
  }

  changeContent(event) {
    const privateClinic = this.privateClinicTarget.dataset.private;

    if (privateClinic === 'true') {
      event.preventDefault();
      $('#appointment-content').addClass('hidden');
      $('#private-fallback-content').removeClass('hidden');
    }
  }
}
