/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */

import { Controller } from '@hotwired/stimulus';

export default class validations extends Controller {
  static targets = ['zipCode', 'errorText', 'insuranceType', 'agesCheckBoxes', 'serviceCheckBoxes',
    'insuranceField', 'insuranceLabel', 'optionalField', 'specialField', 'agesCheckBoxesChecked',
    'insuranceCardNumberField', 'cardAssistanceLabel', 'memberIDNumberField', 'memberIDNumberLabel',
    'supplyInventoryQuantity', 'maxQuantityAlert', 'dateField', 'confirmModal', 'resetable', 'save',
    'priorityGroup'];

  connect() {
    this.validateAgeCheckBoxes();
    this.validateInsurancefields();
    this.validateOptionalInsuranceFields();
    this.validateInsuranceCardNumberField();
    this.validateGroupNumberField();
    this.enableSaveAndContinue();
  }

  preventOutOfRange(event) {
    if (/^0/.test(event.target.value)) {
      event.target.value = event.target.value.replace(/^0/, '');
    }
  }

  validInventory(event) {
    const numericReg = /^\d+$/;

    if (!numericReg.test(event.target.value)) {
      event.target.value = event.target.value.replace(/\D/g, '');
    }

    if (event.target.value >= 2 ** 31) {
      const sliceEnd = -(event.target.value.toString().length - 10);
      event.target.value = event.target.value.slice(0, sliceEnd);
    }
  }

  preventNegativeNumber(event) {
    if ((event.key === '-') || (event.key === '+') || (event.key === '.') || (event.key === 'e')) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  enableSaveAndContinue() {
    const shouldDisable = !(this.hasPriorityGroupTarget && this.priorityGroupTarget.checked);
    if (this.hasSaveTarget) $(this.saveTarget).attr('disabled', shouldDisable);
  }

  resetField() {
    this.resetableTargets.forEach((field) => {
      field.value = '';
    });
  }

  validateDigitsOnly() {
    const regExp = /^\d+$/;
    const { value } = this.zipCodeTarget;

    if (value) {
      if (regExp.test(value) && value.length === 5) {
        this.errorTextTarget.classList.add('hidden');
      } else if (!regExp.test(value) || value.length > 5) {
        this.errorTextTarget.classList.remove('hidden');
      }
    }
  }

  toggleDateModal() {
    this.confirmModalTarget.classList.toggle('hidden');
  }

  handlePastDate(event) {
    const todaysDate = new Date().setHours(0, 0, 0, 0);
    const pastDate = new Date($(event.target).val()).setHours(0, 0, 0, 0) < todaysDate;
    if (!pastDate) return;

    if (this.hasConfirmModalTarget) {
      $(this.confirmModalTarget).find('.date-warning').text('Selected date is in past. Click Confirm if you still want to continue.');
      this.confirmModalTarget.classList.toggle('hidden');
    }
  }

  clearDateInPast() {
    const dateField = $(this.dateFieldTarget);
    dateField.val('');
    dateField.next('input').val('');
    this.confirmModalTarget.classList.toggle('hidden');
  }

  toggleMaxQuantityAlert(event) {
    if (event.target.value === '') return;

    if (parseInt(event.target.value, 10) <= parseInt(event.target.dataset.maxQuantity, 10)) {
      event.target.nextElementSibling.classList.add('hidden');
    } else {
      event.target.nextElementSibling.classList.remove('hidden');
    }
  }

  enableSubmitForm() {
    $('#clinicSubmit').prop('disabled', false);
  }

  validateInventoryQuantity(event) {
    let validInventoryQuantity = true;

    this.supplyInventoryQuantityTargets.forEach((inventory) => {
      if (inventory.value === '') {
        validInventoryQuantity = false;
      }
    });

    if (!validInventoryQuantity) {
      alert('You must enter a starting amount for all vaccines to be decremented from the inventory.');
      event.preventDefault();
    }
  }

  validateAgeCheckBoxes() {
    const allAgesCheckboxId = 'allages';
    let checkedElements = 0;

    this.agesCheckBoxesTargets.forEach((check) => {
      if (check.checked) {
        checkedElements += 1;
        if (check.id === allAgesCheckboxId) {
          this.disableAgeGroups();
        }
      }
    });

    if (checkedElements === 0) {
      this.enableAgeGroups();
    }
  }

  disableAgeGroups() {
    const ages = ['children', 'adults', 'seniors', 'other'];

    ages.forEach((age) => {
      $(`#${age}`).prop('checked', false);
      $(`#${age}`).attr('disabled', true);
      $(`label.${age}`).addClass('text-disabled');
    });
  }

  enableAgeGroups() {
    this.agesCheckBoxesTargets.forEach((check) => {
      $(`#${check.id}`).attr('disabled', false);
      $(`label.${check.id}`).removeClass('text-disabled');
    });
  }

  checkAgeCheckBoxes(event) {
    const ages = ['children', 'adults', 'seniors', 'other'];

    if (event.target.checked && event.target.id === 'allages') {
      ages.forEach((age) => {
        $(`#${age}`).prop('checked', true);
      });
    }
  }

  validateVaccineSelect() {
    if (this.hasServiceCheckBoxesTarget) {
      const vaccinationIsChecked = this.serviceCheckBoxesTargets.filter((el) => el.checked && el.id === 'vaccination').length > 0;

      if (vaccinationIsChecked) {
        $('#vaccinations-select').attr('disabled', false).trigger('chosen:updated');
      } else {
        $('#vaccinations-select').val('').trigger('chosen:updated');
        $('#vaccinations-select').prop('disabled', true).trigger('chosen:updated');
      }
    }
  }

  validateInsurancefields(event) {
    if (this.hasInsuranceTypeTarget) {
      const notRequired = this.insuranceTypeTarget.value === 'No Insurance';

      if (notRequired) {
        this.insuranceLabelTargets.forEach((label) => {
          label.classList.add('hidden');
        });

        this.insuranceFieldTargets.forEach((element) => {
          element.required = false;
          element.disabled = true;
        });

        if (event) {
          $(event.currentTarget).parents('.insurance-fields').find('.flatpickr-date-field').prop('required', false);
          $(event.currentTarget).parents('.insurance-fields').find('.flatpickr-date-field').prop('disabled', true);
        }
      } else {
        const hasDatePickerTarget = this.insuranceFieldTargets.filter((el) => el.name === 'patient[insured_date_of_birth]').length > 0;
        const hasSecondaryDatePickerTarget = this.insuranceFieldTargets.filter((el) => el.name === 'patient[secondary_insured_date_of_birth]').length > 0;

        this.insuranceFieldTargets.forEach((element) => {
          const notRequiredFields = ['patient[card_number_for_insurance]', 'patient[other_insurance]', 'patient[other_secondary_insurance_company_name]'];
          if (!notRequiredFields.includes(element.name) && !element.dataset.validationsTarget.includes('optionalField')) {
            element.required = true;
          }
          element.disabled = false;
        });

        this.insuranceLabelTargets.forEach((label) => {
          label.classList.remove('hidden');
        });

        if ((hasDatePickerTarget || hasSecondaryDatePickerTarget) && event) {
          $(event.currentTarget).parents('.insurance-fields').find('.flatpickr-date-field').prop('required', true);
          $(event.currentTarget).parents('.insurance-fields').find('.flatpickr-date-field').prop('disabled', false);
        }
      }
    }
  }

  validateOptionalInsuranceFields(event) {
    if (this.hasInsuranceTypeTarget) {
      const notRequired = this.insuranceTypeTarget.value === 'No Insurance';

      if (notRequired) {
        this.optionalFieldTargets.forEach((element) => {
          element.disabled = true;
        });

        this.specialFieldTargets.forEach((element) => {
          element.disabled = true;
        });

        if (event) {
          $(event.currentTarget).parents('.insurance-fields').find('.flatpickr-date-field').prop('disabled', true);
        }
      } else {
        let targets;
        if (this.hasSpecialFieldTarget) {
          targets = this.specialFieldTargets;
        } else {
          targets = this.optionalFieldTargets;
        }

        const hasDatePickerTarget = targets.filter((el) => el.name === 'patient[insured_date_of_birth]').length > 0;
        const hasSecondaryDatePickerTarget = targets.filter((el) => el.name === 'patient[secondary_insured_date_of_birth]').length > 0;

        this.optionalFieldTargets.forEach((element) => {
          element.disabled = false;
        });

        this.specialFieldTargets.forEach((element) => {
          element.disabled = false;
        });

        if ((hasDatePickerTarget || hasSecondaryDatePickerTarget) && event) {
          $(event.currentTarget).parents('.insurance-fields').find('.flatpickr-date-field').prop('disabled', false);
        }
      }
    }
  }

  validateInsuranceCardNumberField() {
    // eslint-disable-next-line max-len
    if (this.hasInsuranceTypeTarget && this.hasInsuranceCardNumberFieldTarget && this.hasMemberIDNumberFieldTarget) {
      const required = this.insuranceTypeTarget.value === 'Medicaid/Medical Assistance';
      const noInsurance = this.insuranceTypeTarget.value === 'No Insurance';

      if (noInsurance) {
        this.insuranceCardNumberFieldTarget.disabled = true;
        this.memberIDNumberFieldTarget.disabled = true;
      } else {
        this.insuranceCardNumberFieldTarget.disabled = false;
        this.memberIDNumberFieldTarget.disabled = false;

        $(this.insuranceCardNumberFieldTarget).attr('required', required);
        $(this.cardAssistanceLabelTarget).toggle(required);

        $(this.memberIDNumberFieldTarget).attr('required', required);
        $(this.memberIDNumberLabelTarget).toggle(required);
      }
    }
  }

  validateGroupNumberField() {
    if (this.hasGroupNumberField) {
      this.groupNumberField.required = false;
    }
  }

  validateSuffixRequired(event) {
    const name = event.target.value;
    const suffix = $(event.target).closest('.clinic_new_personnel').find('.suffix')[0];

    if (name) {
      suffix.required = suffix.dataset.required === 'true';
    } else {
      suffix.required = false;
    }
  }

  cleanErrors() {
    const erroredFields = document.querySelectorAll('.is-error');
    const errorMessages = document.querySelectorAll('.is-error + .text-red-600, .is-error .text-red-600');

    errorMessages.forEach((message) => {
      message.remove();
    });

    erroredFields.forEach((el) => {
      el.classList.remove('is-error');
    });
  }
}
