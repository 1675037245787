$(document).on('turbolinks:load', () => {
  const copyLink = () => {
    const copyText = document.getElementById('copy-link');
    copyText.select();
    document.execCommand('copy');
  };

  $('.click-to-copy').click(() => {
    copyLink();
  });
});
