/* eslint-disable no-use-before-define */
/* global Awesomplete */

$(document).on('turbolinks:load', () => {
  // TODO: this file needs a refactor, most of these functions have repeated logic.

  $('form').on('click', '.js_remove_record', (event) => {
    $(event.currentTarget).prev('input[type=hidden]').val('1');
    $(event.currentTarget).closest('.clinic_new_personnel').hide();
    $(event.currentTarget).closest('.clinic_new_personnel').find('.suffix').removeAttr('required');

    return event.preventDefault();
  });

  $('form').on('click', '.remove-break', (event) => {
    $(event.currentTarget).closest('.clinic-break').children('.destroyed-element').val('1');
    $(event.currentTarget).closest('.clinic-break').hide();
    // TODO: when applying front validations to edit:
    //       remove required from fields to submit the form.

    return event.preventDefault();
  });

  $('form').on('click', '.add_a_break', (event) => {
    const time = new Date().getTime();
    const regexp = new RegExp($(event.currentTarget).data('id'), 'g');
    $('.breaks').append($(event.currentTarget).data('fields').replace(regexp, time));
    return event.preventDefault();
  });

  $('form').on('click', '.add_fields', (event) => {
    const time = new Date().getTime();
    const regexp = new RegExp($(event.currentTarget).data('id'), 'g');
    $('.fields').append($(event.currentTarget).data('fields').replace(regexp, time));
    return event.preventDefault();
  });

  $('form').on('click', '.remove_additional_question_record', (event) => {
    $(event.currentTarget).prev('input[type=hidden]').val('1');
    $(event.currentTarget).closest('.additional_question').hide();

    return event.preventDefault();
  });

  $('form').on('click', '.add_additional_question', (event) => {
    const time = new Date().getTime();
    const regexp = new RegExp($(event.currentTarget).data('id'), 'g');
    $('.additional_question_fields').append($(event.currentTarget).data('fields').replace(regexp, time));
    return event.preventDefault();
  });

  $('form').on('click', '.remove_test_kit_record', (event) => {
    $(event.currentTarget).prev('input[type=hidden]').val('1');
    $(event.currentTarget).closest('.new_test_kit_row').hide();
    return event.preventDefault();
  });

  $('form').on('click', '.add_test_kit_fields', (event) => {
    const time = new Date().getTime();
    const regexp = new RegExp($(event.currentTarget).data('id'), 'g');
    $('#testKits').append($(event.currentTarget).data('fields').replace(regexp, time));
    Awesomplete.$$('input.awesomplete').forEach((input) => {
      // eslint-disable-next-line no-new
      new Awesomplete(input);
    });
    return event.preventDefault();
  });

  awesomeComplete();

  addFormField('.addClinicDate', '.clinicFieldsContainer');
  removeFormField('.removeClinicDatefield', '.nestedClinicDate');
});

function addFormField(klassContainer, fieldsContainer) {
  $('form').on('click', klassContainer, (event) => {
    const time = new Date().getTime();
    const regexp = new RegExp($(event.currentTarget).data('id'), 'g');
    $(fieldsContainer).append($(event.currentTarget).data('fields').replace(regexp, time));
    return event.preventDefault();
  });
}

function removeFormField(buttonKlass, fieldContainer) {
  $('form').on('click', buttonKlass, (event) => {
    $(event.currentTarget).prev('input[type=hidden]').val('1');
    $(event.currentTarget).closest(fieldContainer).hide();
    return event.preventDefault();
  });
}

function awesomeComplete() {
  const kitsTableRows = document.querySelectorAll('#testKits tr');

  if (kitsTableRows[kitsTableRows.length - 1]) {
    kitsTableRows[kitsTableRows.length - 1].querySelectorAll('.awesomplete').forEach((elem) => {
      elem.classList.add('awesomplete-last-row');
    });
  }
}
