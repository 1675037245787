/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
import 'trix';

const disableAttachment = (trixContent) => {
  if (trixContent.classList.contains('disable-attachment')) {
    trixContent.parentNode.querySelector('.trix-button-group.trix-button-group--file-tools').remove();
    trixContent.addEventListener('trix-file-accept', (event) => event.preventDefault());
  }
};

// eslint-disable-next-line import/prefer-default-export
export const setTargetBlankOnLinks = (linksSelector, onlyExternal = false) => {
  const links = (typeof linksSelector === 'string' && document.querySelectorAll(linksSelector)) || linksSelector;

  links.forEach((link) => {
    if (onlyExternal) {
      if (link.host !== location.host) {
        link.target = '_blank';
      }
    } else {
      link.target = '_blank';
    }
  });
};

const formatTrixContents = (trixContents) => (event) => {
  event.preventDefault();

  trixContents.forEach((trixContent) => {
    trixContent.querySelectorAll('a').forEach((anchor) => {
      anchor.classList.add('trix-content-link');
    });

    trixContent.querySelectorAll('ul').forEach((list) => {
      list.classList.add('trix-content-bullet-list');
    });

    trixContent.querySelectorAll('ol').forEach((list) => {
      list.classList.add('trix-content-number-list');
    });

    trixContent.parentNode.querySelector("input[type='hidden']").value = trixContent.innerHTML;
  });

  event.currentTarget.submit();
};

document.addEventListener('turbolinks:load', () => {
  const trixContents = document.querySelectorAll('.trix-content');
  trixContents.forEach(disableAttachment);

  const trixForm = document.querySelector('.js-trix-form');
  if (trixForm) {
    trixForm.addEventListener('submit', formatTrixContents(trixContents));
  }

  setTargetBlankOnLinks('a.trix-content-link', true);
});
