/* global I18n */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['emailField', 'confirmEmailField', 'confirmEmailError', 'notice'];

  connect() {
    this.emailConfirmation();
  }

  emailConfirmation() {
    if (this.hasEmailFieldTarget) {
      const emailFieldValue = this.emailFieldTarget.value;
      const confirmEmailFieldValue = this.confirmEmailFieldTarget.value;
      if ((emailFieldValue.toLowerCase() !== confirmEmailFieldValue.toLowerCase())
        && (emailFieldValue !== '' && confirmEmailFieldValue !== '')) {
        this.confirmEmailErrorTarget.innerText = I18n.errors.confirmation_email_does_not_match;
        document.getElementById('submitButton').setAttribute('disabled', 'true');

        const submitWithoutVerificationButton = document.getElementById('skip-email-verification-yes');
        if (submitWithoutVerificationButton) {
          submitWithoutVerificationButton.setAttribute('disabled', true);
        }
      } else {
        this.confirmEmailErrorTarget.innerText = '';
        const element = document.getElementById('submitButton');
        if (element) { element.removeAttribute('disabled'); }

        const submitWithoutVerificationButton = document.getElementById('skip-email-verification-yes');
        if (submitWithoutVerificationButton) {
          submitWithoutVerificationButton.removeAttribute('disabled');
        }
      }
    }

    if (this.hasNoticeTarget) {
      $(this.noticeTarget).toggle(!this.emailFieldTarget.value);
    }
  }
}
