import { Controller } from '@hotwired/stimulus';
import { setTargetBlankOnLinks } from '../packs/trix';

export default class extends Controller {
  connect() {
    this.postProcessLinks();
  }

  postProcessLinks() {
    setTargetBlankOnLinks(this.links);
    this.styleLinks();
  }

  styleLinks() {
    this.links.forEach((link) => {
      link.classList.add('underline', 'text-brand-blue-600');
    });
  }

  get links() {
    return this.element.querySelectorAll('a');
  }
}
