/* global SignaturePad */
/* eslint-disable class-methods-use-this */

import { Controller } from '@hotwired/stimulus';

let signaturePad;

export default class extends Controller {
  static targets = ['title', 'fullName', 'signatureInput', 'buttonTitle', 'translations', 'clearSignature', 'canvas'];

  connect() {
    this.initializeCanvas();
    if (this.hasCanvasTarget) {
      signaturePad = new SignaturePad(this.canvasTarget);
    }
  }

  isFullNameEntered(firstName, lastName) {
    if ((firstName === '') || (lastName === '')) {
      return false;
    }

    return true;
  }

  isSignatureEntered(signature) {
    if ((signature.length === 0) || (signature.value === '') || (signature.getAttribute('src') === '')) {
      return false;
    }

    return true;
  }

  toggleSignature() {
    if (this.fullNameTarget.classList.contains('hidden')) {
      this.titleTarget.innerText = this.translationsTarget.dataset.signerLabel;
      this.buttonTitleTarget.innerText = this.translationsTarget.dataset.signBtnTitle;
      this.signatureInputTarget.classList.add('hidden');
      this.fullNameTarget.classList.remove('hidden');
      if (this.hasClearSignatureTarget) {
        this.clearSignatureTarget.classList.add('hidden');
      }
    } else {
      this.titleTarget.innerText = this.translationsTarget.dataset.signatureLabel;
      this.buttonTitleTarget.innerText = this.translationsTarget.dataset.signatureBtnTitle;
      this.fullNameTarget.classList.add('hidden');
      this.signatureInputTarget.classList.remove('hidden');
      if (this.hasClearSignatureTarget) {
        this.clearSignatureTarget.classList.remove('hidden');
      }
    }
  }

  onRemoveFamilyMember(e) {
    const className = `member-${e.currentTarget.dataset.member}`;
    const memberObjectId = document.getElementsByClassName(className)[0];

    memberObjectId.querySelector('input[type=hidden]').value = 'true';
    memberObjectId.style.display = 'none';
    memberObjectId.querySelector('.family-member-form').remove();
  }

  validateSignatoryData(event) {
    const errorMessage = $('#signatureErrorMessage');
    const firstName = $('#registration_signatory_first_name');
    const lastName = $('#registration_signatory_last_name');

    if ((firstName.val() === '' || lastName.val() === '') && signaturePad?.points?.length === 0) {
      errorMessage.removeClass('hidden');
      event.preventDefault();
    } else {
      $('#hidden_signature_data').val(signaturePad.toDataURL());
      errorMessage.addClass('hidden');
    }
  }

  clearSignature(event) {
    event.preventDefault();
    signaturePad.clear();
  }

  changeAtiveTab(event) {
    let element = $(event.target)[0];
    const tab = $(`#${$(element).data('tab-id')}`);

    while (element.nodeName !== 'A') {
      element = element.parentNode;
    }

    const aElements = $(element.parentNode.parentNode).find('li > a');
    const tabContents = $('#tabs-id').find('.tab-content > div');

    for (let i = 0; i < aElements.length; i++) {
      aElements[i].classList.remove('text-white');
      aElements[i].classList.remove('bg-blue-600');
      aElements[i].classList.add('text-black-600');
      aElements[i].classList.add('bg-white');
      tabContents[i].classList.add('hidden');
      tabContents[i].classList.remove('block');
    }

    element.classList.remove('text-blue-600', 'bg-white');
    element.classList.add('text-white', 'bg-blue-600');

    tab.removeClass('hidden');
    tab.addClass('block');
  }

  initializeCanvas() {
    if (this.hasCanvasTarget) {
      const canvas = $(this.canvasTarget)[0];
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext('2d').scale(ratio, ratio);
    }
  }
}
