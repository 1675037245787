$(document).on('turbolinks:load', () => {
  if ($('.password-strength')) {
    $('.password-strength').focus(() => {
      $('#password-message').show();
    });

    $('.password-strength').blur(() => {
      $('#password-message').hide();
    });

    $('.submit-password').click((event) => {
      if ($('.invalid').length > 0) {
        $('#password-message').show();
        event.preventDefault();
      }
    });

    const keyUpPaste = () => {
      const lowerCaseLetters = /^(?=.*[a-z])/;
      if ($('.password-strength').val().match(lowerCaseLetters)) {
        $('#lowercase').removeClass('invalid');
        $('#lowercase').addClass('valid');
      } else {
        $('#lowercase').removeClass('valid');
        $('#lowercase').addClass('invalid');
      }

      const upperCaseLetters = /^(?=.*[A-Z])/;
      if ($('.password-strength').val().match(upperCaseLetters)) {
        $('#uppercase').removeClass('invalid');
        $('#uppercase').addClass('valid');
      } else {
        $('#uppercase').removeClass('valid');
        $('#uppercase').addClass('invalid');
      }

      const numbers = /^(?=.*[0-9])/;
      if ($('.password-strength').val().match(numbers)) {
        $('#digit').removeClass('invalid');
        $('#digit').addClass('valid');
      } else {
        $('#digit').removeClass('valid');
        $('#digit').addClass('invalid');
      }

      const characterLength = /^(?=.{12,})/;
      if ($('.password-strength').val().match(characterLength)) {
        $('#length').removeClass('invalid');
        $('#length').addClass('valid');
      } else {
        $('#length').removeClass('valid');
        $('#length').addClass('invalid');
      }

      const characters = /^(?=.*[%&*$!@#])/;
      if ($('.password-strength').val().match(characters)) {
        $('#character').removeClass('invalid');
        $('#character').addClass('valid');
      } else {
        $('#character').removeClass('valid');
        $('#character').addClass('invalid');
      }
    };

    $('.password-strength').on('keyup paste', () => {
      setTimeout(keyUpPaste, 50);
    });
  }
});
