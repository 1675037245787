/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['exportableColumn', 'clinicReport', 'patientReport'];

  // eslint-disable-next-line class-methods-use-this
  getReportType() {
    return document.querySelector('input[name=report_type]:checked').value;
  }

  // eslint-disable-next-line class-methods-use-this
  getCheckedReports() {
    return Array.from(document.querySelectorAll('input[type="checkbox"].report-checkbox')).filter((checkbox) => checkbox.checked);
  }

  export(event) {
    if (this.getCheckedReports().length === 0 && this.getReportType() !== 'registration_list') {
      event.preventDefault();
      alert('Must select at-least one column to export.');
    }
  }

  show_clinic_patient_or_none() {
    switch (this.getReportType()) {
      case 'clinic_report':
        this.patientReportTarget.classList.add('hidden');
        this.clinicReportTarget.classList.remove('hidden');
        break;
      case 'patient_report':
        this.clinicReportTarget.classList.add('hidden');
        this.patientReportTarget.classList.remove('hidden');
        break;
      default:
        this.patientReportTarget.classList.add('hidden');
        this.clinicReportTarget.classList.add('hidden');
    }
    Array.from(this.exportableColumnTargets).forEach((exportableColumn) => {
      exportableColumn.checked = false;
    });
  }
}
