$(document).on('turbolinks:load', () => {
  $('.search-by-radio').change(() => {
    $('.submit-form').click();
  });
  $('.hide-county-code').hide();
  if ($(":radio[value='Private - Open']").prop('checked')) {
    $('#clinic_date').prop('disabled', true);
    $('#clinic_clinic_date').prop('disabled', true);
    $('.cl-time').prop('disabled', true);
    document.getElementsByClassName('cl-date-picker').flatpickr({
      disable: [
        true,
      ],
    });
  }
  $('.clinic-activity-time').prop('disabled', true);
});
