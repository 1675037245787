/* eslint-disable class-methods-use-this, no-alert */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['searchableRow', 'bulkEmailConfirmationModal', 'bulkInvitationConfirmationModal', 'emailOption', 'emailOptionCheckbox', 'familyMemberIncompleteProcessModal'];

  connect() {
    this.pollInterval = null;
    if (this.checkHistories()) {
      this.startRefreshing();
    }
  }

  startRefreshing() {
    this.pollInterval = setInterval(() => {
      if (this.checkHistories()) {
        this.updatePatientHistories();
      } else {
        // Stop polling if no longer waiting for histories
        clearInterval(this.pollInterval);
      }
    }, 5000);
  }

  disconnect() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }

  checkHistories() {
    return !!$('.check-history-container .vaccine-history-spinner').not('.hidden').length;
  }

  search(event) {
    const searchTerm = event.target.value.toLowerCase();
    this.searchableRowTargets.forEach((el) => {
      const filterableKey = el.getAttribute('data-search-content');
      el.classList.toggle('hidden', !filterableKey.includes(searchTerm));
    });
  }

  emailVaccinationRecord(event) {
    event.preventDefault();

    if (!$('.bulk-check:checked').length > 0) {
      alert('Must select atleast one patient.');
    } else {
      $('.action-type').val('bulk_vaccination_record');
      $('#bulk-email-form').submit();
    }
  }

  setEmailCancellationOption(event) {
    this.emailOptionTarget.value = event.target.checked;
  }

  emailReminder(event) {
    event.preventDefault();

    if (!$('.bulk-check:checked').length > 0) {
      alert('Must select at least one patient.');
    } else {
      // eslint-disable-next-line func-names
      $('.bulk-check').each(function () {
        $(this).val($(this).data('appointment-id'));
      });
      $('.action-type').val('bulk_reminder');
      $('#bulk-email-form').submit();
    }
  }

  viewConsent(event) {
    if (!$('.bulk-check:checked').length > 0) {
      alert('Must select at least one patient.');
    } else if (event.target.getAttribute('data-force-disable') !== 'true') {
      $('.action-type').val('bulk_view_consent');
      $('#bulk-email-form').attr('target', '_blank').submit();
    }
  }

  emailReminderSingle(event) {
    event.preventDefault();
    const appointmentId = $(event.target).data('appointment-id');

    if (appointmentId) {
      const checkbox = $(`input[data-appointment-id='${appointmentId}']`);
      checkbox.prop('checked', true);
      checkbox.val(appointmentId);
      $('.action-type').val('bulk_reminder');
      $('#bulk-email-form').submit();
    }
  }

  emailInvitation(event) {
    event.preventDefault();

    if (!$('.bulk-check:checked').length > 0) {
      alert('Must select atleast one patient.');
    } else {
      $('.action-type').val('bulk_invitation');
      $('#bulk-email-form').submit();
    }
  }

  bulkCancelAppointments(event) {
    event.preventDefault();

    if (!$('.bulk-check:checked').length > 0) {
      alert('Must select at least one patient.');
    } else {
      $('.action-type').val('bulk_cancel_appointments');
      $('#bulk-email-form').trigger('submit');
    }
  }

  handleBulkVaccinationRecordCheck() {
    if ($('.bulk-check:checked').length > 0) {
      $('.bulk-vaccination-email').removeClass('grey-btn');
      $('.bulk-vaccination-email').prop('disabled', false);
    } else {
      $('.bulk-vaccination-email').addClass('grey-btn');
      $('.bulk-vaccination-email').prop('disabled', true);
    }

    let enable = false;
    if ($('.bulk-check:checked').length > 1) {
      // eslint-disable-next-line func-names
      $('.bulk-check:checked').each(function () {
        if (this.dataset.hasAppointment === 'true') {
          enable = true;
        }
      });
    } else if ($('.bulk-check:checked').length === 1) {
      enable = $('.bulk-check:checked')[0].dataset.hasAppointment === 'true';
    }

    if (enable) {
      $('.bulk-reminder-email').removeClass('grey-btn');
      $('.bulk-reminder-email').prop('disabled', false);
    } else {
      $('.bulk-reminder-email').addClass('grey-btn');
      $('.bulk-reminder-email').prop('disabled', true);
    }

    if (enable && document.querySelector('[data-action="patients#viewConsent"]').getAttribute('data-force-disable') === 'true') {
      document.querySelector('[data-action="patients#viewConsent"]').classList.add('grey-btn');
      document.querySelector('[data-action="patients#viewConsent"]').setAttribute('disabled', true);
    }
  }

  toggleConfirmationModal(event) {
    event.preventDefault();

    this.toggleEmailCancellationOption(event.target.dataset.emailCancellationOpion);
    this.bulkEmailConfirmationModalTarget.classList.toggle('hidden');

    const confirmButton = $(this.bulkEmailConfirmationModalTarget).find('.confirm-button');
    confirmButton.attr('data-action', $(event.target).data('next-target'));
    confirmButton.attr('data-appointment-id', $(event.target).data('appointment-id'));

    const replacements = {
      '%SELECTED%': $('.bulk-check:checked').length,
      '%PATIENT%': $('.bulk-check:checked').length === 1 ? 'patient' : 'patients',
      '%APPOINTMENT%': $('.bulk-check:checked').length === 1 ? 'appointment ' : 'appointments',
      '%EMAIL%': $('.bulk-check:checked').length === 1 ? 'Email' : 'Emails',
    };

    let description = $(event.target).data('description');
    description = this.replace(description, replacements);

    let emailCancellationText = $(event.target).data('email-cancellation-description');
    emailCancellationText = this.replace(emailCancellationText, replacements);

    $(this.bulkEmailConfirmationModalTarget).find('.description').text(description);
    $(this.bulkEmailConfirmationModalTarget).find('.email-cancellation-description').text(emailCancellationText);
  }

  replace(content, replacements) {
    let newContent;

    if (content) {
      newContent = content.replace(/%\w+%/g, (all) => replacements[all] || all);
    }

    return newContent;
  }

  showFamilyMemberIncompleteProcessModal(event) {
    event.preventDefault();
    this.familyMemberIncompleteProcessModalTarget.classList.remove('hidden');
  }

  closeFamilyMemberIncompleteProcessModal() {
    this.familyMemberIncompleteProcessModalTarget.classList.add('hidden');
  }

  toggleEmailCancellationOption(value) {
    if (value === 'true') {
      this.emailOptionCheckboxTarget.classList.remove('hidden');
    } else {
      this.emailOptionCheckboxTarget.classList.add('hidden');
    }
  }

  toggleInvitationConfirmationModal(event) {
    event.preventDefault();
    this.bulkInvitationConfirmationModalTarget.classList.toggle('hidden');
  }

  updatePatientHistories() {
    const clinicIdValue = this.element.getAttribute('data-patients-clinic-id-value');
    const path = `/clinics/${clinicIdValue}/patients`;
    const rows = this.searchableRowTargets;

    $.ajax({
      url: path,
      method: 'GET',
      dataType: 'JSON',
      success(patients) {
        rows.forEach((el) => {
          const patientId = parseInt(el.getAttribute('data-patient-id'), 10);
          const newPatientData = patients.find((patient) => parseInt(patient.id, 10) === patientId);
          const historyContainer = $(el).find('.check-history-container').first();
          historyContainer.children().addClass('hidden');

          if (!newPatientData.query_iis_allowed) {
            historyContainer.find('.vaccine-history-unavailable').removeClass('hidden');
          } else if (newPatientData.vaccine_history_available) {
            historyContainer.find('.check-history').removeClass('hidden');
          } else if (newPatientData.fetching_vaccine_history) {
            historyContainer.find('.vaccine-history-spinner').removeClass('hidden');
          }
        });
      },
    });
  }
}
