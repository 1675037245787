/* eslint-disable no-use-before-define */
/* eslint-disable no-alert */
/* global I18n */

$(document).on('turbolinks:load', () => {
  validateDateOfBirth();
});

function validateDateOfBirth() {
  $('.submit-patient').click((event) => {
    let date = [];

    $('.date-of-birth').each((_, e) => {
      date.push($(e).val());
    });

    date = date.includes('') ? '' : new Date(date);

    if (date) {
      const dob = new Date(date);
      const today = new Date();
      const age = Math.floor((today - dob) / (365.25 * 24 * 60 * 60 * 1000));

      if (age < 0) {
        alert(I18n.errors.dob_in_past);
        event.preventDefault();
      }
    }
  });
}
