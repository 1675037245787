import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['searchableRow', 'employer', 'locations', 'companyCheckbox',
    'companyInputField', 'locationsCheckboxes', 'hasOtherReason', 'otherReasonExplanation',
    'otherReasonExplanationArea', 'emailField', 'confirmEmailField', 'confirmEmailError',
    'relationSelect', 'firstName', 'lastName'];

  connect() {
    $(document).click((e) => {
      if (e.target.id !== 'predict_company_name' && !$('#locationsCheckboxes').find(e.target).length) {
        $('#locationsCheckboxes').removeClass('active');
      }
    });

    const that = this;
    if (this.hasOtherReasonTargets) {
      that.toggleHasOtherReasonBox();
    }
    this.defaultRelationChange();
  }

  showCheckboxes() {
    this.locationsCheckboxesTarget.classList.toggle('active');
  }

  search(event) {
    const searchTerm = event.target.value.toLowerCase();
    this.searchableRowTargets.forEach((el) => {
      const filterableKey = el.getAttribute('data-search-content');
      el.classList.toggle('hidden', !filterableKey.includes(searchTerm));
    });
  }

  displayLocations(e) {
    if (e.currentTarget.checked && e.currentTarget.dataset.type === 'employer') {
      this.toggleModal(e);
      this.employerTarget.innerText = e.currentTarget.getAttribute('data-employer');
      this.locationsTarget.innerText = e.currentTarget.getAttribute('data-locations');
    }

    if ($('.form-checkbox:checked').length > 0) {
      const selected = this.companyCheckboxTargets.filter((target) => target.checked).map((target) => target.dataset.employer).join('<br/> ');
      this.companyInputFieldTarget.innerHTML = `<b>Selected:</b> <br/>${selected}`;
    } else {
      this.companyInputFieldTarget.innerHTML = '';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  toggleModal() {
    const modal = document.getElementById('businessLocationsModal');
    modal.classList.toggle('opacity-0');
    modal.classList.toggle('pointer-events-none');
  }

  toggleHasOtherReasonBox() {
    const that = this;
    this.hasOtherReasonTargets.forEach((item, index) => {
      if (item.checked && item.value === 'Yes') {
        that.otherReasonExplanationTargets[index].classList.remove('hidden');
      } else if (that.otherReasonExplanationTargets[index]) {
        that.otherReasonExplanationTargets[index].classList.add('hidden');
        that.otherReasonExplanationAreaTargets[index].value = '';
      }
    });
  }

  defaultRelationChange() {
    if (this.hasRelationSelectTarget) {
      if ($(this.relationSelectTarget).val().toLowerCase() === 'self') {
        $(this.firstNameTarget).val($('#patient_first_name').val());
        $(this.lastNameTarget).val($('#patient_last_name').val());
      } else {
        $(this.firstNameTarget).val($('#patient_signer_first_name').val());
        $(this.lastNameTarget).val($('#patient_signer_last_name').val());
      }
    }
  }

  relationChange() {
    if (this.hasRelationSelectTarget) {
      if ($(this.relationSelectTarget).val().toLowerCase() === 'self') {
        $(this.firstNameTarget).val($('#patient_first_name').val());
        $(this.lastNameTarget).val($('#patient_last_name').val());
        const event = new Event('change', { bubbles: true });
        this.firstNameTarget.dispatchEvent(event);
        this.lastNameTarget.dispatchEvent(event);
      } else {
        $(this.firstNameTarget).val('');
        $(this.lastNameTarget).val('');
      }
    }
  }

  patientRegistrationRelationChange() {
    if (this.hasRelationSelectTarget) {
      if ($(this.relationSelectTarget).val().toLowerCase() === 'self') {
        $(this.firstNameTarget).val($('#patient_registration_first_name').val());
        $(this.lastNameTarget).val($('#patient_registration_last_name').val());
        const event = new Event('change', { bubbles: true });
        this.firstNameTarget.dispatchEvent(event);
        this.lastNameTarget.dispatchEvent(event);
      } else {
        $(this.firstNameTarget).val('');
        $(this.lastNameTarget).val('');
      }
    }
  }
}
