import { Controller } from '@hotwired/stimulus';

const checkDeadlineGreaterThanClinicDate = (deadline, clinicDate) => {
  const deadlineDate = new Date(deadline);
  const clinicDateFromParam = new Date(clinicDate);

  return +deadlineDate > +clinicDateFromParam;
};

export default class extends Controller {
  static targets = ['clinicDate', 'registrationDeadline', 'confirmModal', 'clearButton',
    'startTime', 'endTime', 'startMeridiem', 'endMeridiem', 'vaccinesSelect', 'itemTypesSelect',
    'appointmentYesRequiredRadio', 'appointmentNoWalkInRadio', 'breakField', 'clinicTimeChangeModal'];

  static values = { clinicTimeChangeAllowed: { type: Boolean, default: false } };

  connect() {
    this.setSettingsAndVaccination();
    this.hideOrShowAdditionalQuestions();
    this.requireVaccine();

    $(document).on('turbolinks:load', () => {
      this.fixInvalidClinicConsentField();
    });
  }

  requireVaccine() {
    if (this.hasVaccinesSelectTarget && $('#vaccination-label')[0].dataset.required === 'true') {
      this.vaccinesSelectTarget.required = 'required';
    }
    if (this.hasitemTypesSelectTarget && $('#item-type-label')[0].dataset.required === 'true') {
      this.itemTypesSelectTarget.required = 'required';
    }
  }

  handleSubmit(event) {
    for (const field of this.breakFieldTargets) {
      if (field.classList.contains('is-error')) {
        const errorContainer = document.getElementById('error-messages');
        errorContainer.classList.remove('hidden');

        const errorsList = errorContainer.getElementsByTagName('ul')[0];
        errorsList.innerHTML = "<li>Breaks can't overlap and must be included in the clinic time range.</li>";

        window.scrollTo({top: 0, behavior: 'smooth'});
        event.preventDefault();
        break;
      }
    };

    if (!this.registrationDeadlineTarget.value) {
      return;
    }

    let validDate = true;
    const deadlineValue = this.registrationDeadlineTarget.value;

    if (deadlineValue == null || deadlineValue === '') {
      return;
    }

    this.clinicDateTargets.forEach((el) => {
      if (el.value && checkDeadlineGreaterThanClinicDate(deadlineValue, el.value)) {
        validDate = false;
      }
    });

    if (!validDate) {
      alert('Registration deadline should not be greater than clinic date.'); // eslint-disable-line no-alert
      event.preventDefault();
    }
  }

  checkDeadlineDateWithClinicDate(event) {
    let isValid = true;
    const deadlineValue = this.registrationDeadlineTarget.value;

    if (deadlineValue == null || deadlineValue === '') {
      return;
    }

    this.clinicDateTargets.forEach((el) => {
      if (el.value && checkDeadlineGreaterThanClinicDate(deadlineValue, el.value)) {
        isValid = false;
      }
    });

    if (!isValid) {
      alert('Registration deadline should not be greater than clinic date.'); // eslint-disable-line no-alert
      this.clearRegistrationDeadlineDate();
      event.preventDefault();
    }
  }

  pastDate(event) {
    const pastDate = new Date($(event.target).val()).setHours(0, 0, 0, 0)
                     < new Date().setHours(0, 0, 0, 0);
    const hasAppointments = $(event.target).data('appointments-count') > 0;
    if (!pastDate && !hasAppointments) return;

    let warning = pastDate ? 'Selected date is in past. ' : '';
    if (hasAppointments) warning += 'This clinic already has patient registrations. If you change the clinic date, the patients will be alerted about their new clinic date, ';
    $(this.confirmModalTarget).find('.date-warning').text(`${warning} Click confirm if you still want to continue.`);
    $(this.clearButtonTarget).data('clinic-index', $(event.target).index('.clinic-date:hidden'));
    this.confirmModalTarget.classList.toggle('hidden');
  }

  clearDate() {
    const dateField = $(this.clinicDateTargets[$(this.clearButtonTarget).data('clinic-index')]);
    dateField.val('');
    dateField.next('input').val('');
    this.confirmModalTarget.classList.toggle('hidden');
  }

  clearRegistrationDeadlineDate() {
    const dateField = $(this.registrationDeadlineTarget);
    dateField.val('');
    dateField.next('input').val('');
  }

  toggleDeleteModal() {
    this.confirmModalTarget.classList.toggle('hidden');
  }

  setSettingsAndVaccination() {
    this.setVaccinationOnChange();
  }

  setVaccinationOnChange() {
    const selectedOptions = $('#clinic_clinic_type').data('selectedOptions');

    $('#item-type-select').empty();
    $.each(this.itemTypeOptions(), (key, value) => {
      $('#item-type-select').append($('<option>Select</option>')
        .attr('value', value).text(key).attr('selected', selectedOptions.includes(value)));
    });
    $('#item-type-select').trigger('chosen:updated');
  }

  itemTypeOptions() {
    const clinicData = $('#clinic_clinic_type');
    switch (this.clinicType()) {
      case 'covid':
        return clinicData.data('covid-options');
      case 'testing':
        return clinicData.data('testing-kit-options');
      default:
        return clinicData.data('vaccine-options');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  clinicType() {
    return $('#clinic_clinic_type').val();
  }

  handleChange() {
    this.setVaccinationOnChange();
    this.setTestingLabels();
    this.hideOrShowAdditionalQuestions();
  }

  handleEditChange() {
    this.setVaccinationOnChange();
    this.setTestingLabels();
    this.hideOrShowAdditionalQuestions();
  }

  // eslint-disable-next-line class-methods-use-this
  hideOrShowAdditionalQuestions() {
    if ($('#clinic_clinic_type').val() === 'combined') {
      $('.additional_questions').show();
    } else {
      $('.additional_questions').hide();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setTestingLabels() {
    const clinicType = $('#clinic_clinic_type').val();
    const requiredAsteric = '<span class="field-required">*</span>';
    const vaccinationsRequired = $('#item-type-label').data('required');
    let vaccinationLabelText = '';
    if (clinicType === 'testing') {
      $('#inventory-label').text('Testing Inventory');
      vaccinationLabelText = 'Tests';
    } else {
      $('#inventory-label').text('Clinic Inventory');
      vaccinationLabelText = 'Vaccinations';
    }

    if (vaccinationsRequired) {
      $('#item-type-label').html(`${vaccinationLabelText} ${requiredAsteric}`);
    } else {
      $('#item-type-label').text(vaccinationLabelText);
    }
  }

  handleChangePublicPrivateClinics(event) {
    if (event.target.value === 'Private - Open') {
      $('#clinic_date').prop('disabled', true);
      $('.clinic-date').prop('value', null);
      $('.cl-time').prop('disabled', true);
      this.startTimeTarget.value = null;
      this.endTimeTarget.value = null;
      this.startMeridiemTarget.value = null;
      this.endMeridiemTarget.value = null;
      $('#add-another-child').attr('disabled', 'disabled');
      document.getElementsByClassName('cl-date-picker').flatpickr({
        disable: [
          true,
        ],
      });
      if (this.appointmentYesRequiredRadioTarget && this.appointmentNoWalkInRadioTarget) {
        this.appointmentYesRequiredRadioTarget.disabled = true;
        this.appointmentYesRequiredRadioTarget.checked = false;
        this.appointmentNoWalkInRadioTarget.checked = true;
      }
    } else {
      $('.clinic-date').prop('disabled', false);
      $('.cl-time').prop('disabled', false);
      $('#add-another-child').removeAttr('disabled');
      $('.cl-date-picker').removeAttr('readonly');
      $('#clinic_clinic_date').prop('disabled', false);
      if (this.appointmentYesRequiredRadioTarget && this.appointmentNoWalkInRadioTarget) {
        this.appointmentYesRequiredRadioTarget.disabled = false;
      }
    }
  }

  clinicTimeChanged() {
    const hasAppointments = parseInt(this.data.get('appointments-count'), 10) > 0;
    if (hasAppointments && !this.clinicTimeChangeAllowedValue) {
      this.toggleTimeChangeModal();
    }
  }

  acceptClinicTimeChange() {
    this.clinicTimeChangeAllowedValue = true;
    this.toggleTimeChangeModal();
  }

  toggleTimeChangeModal() {
    this.clinicTimeChangeModalTarget.classList.toggle('hidden');
  }

  resetClinicTime() {
    this.clinicTimeChangeAllowedValue = false;
    this.startTimeTarget.value = this.startTimeTarget.dataset.previousValue;
    this.endTimeTarget.value = this.endTimeTarget.dataset.previousValue;
    this.startMeridiemTarget.value = this.startMeridiemTarget.dataset.previousValue;
    this.endMeridiemTarget.value = this.endMeridiemTarget.dataset.previousValue;
    this.toggleTimeChangeModal();
  }

  // The original field that binds the chosen component has its opacity set to 0, which prevents
  // the border to show when the field is focused on form validation. This method fixes that by
  // setting the focus on the chosen component (by firing chosen:activate) when the field is focused.
  fixInvalidClinicConsentField() {
    const clinicConsentTemplateIdField = document.getElementById('clinic_consent_template_id');

    clinicConsentTemplateIdField.addEventListener('focus', () => {
      // Delay so the browser's validation message is shown before the chosen component is activated
      setTimeout(() => {
        clinicConsentTemplateIdField.dispatchEvent(new Event('chosen:activate'));
      }, 1000);
    });
  }
}
