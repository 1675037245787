/* global I18n */
import { Controller } from '@hotwired/stimulus';

const moment = require('moment');

const MONTHS_IN_A_YEAR = 12;
export default class extends Controller {
  static targets = ['dobField', 'ageField'];

  connect() {
    this.setAgeField();
  }

  birthdayToDate() {
    const singleDateField = this.dobFieldTargets.length === 1;
    const threeDateField = this.dobFieldTargets.length === 3;
    let birthdayToDate = null;

    if (singleDateField) {
      birthdayToDate = new Date(this.dobFieldTarget.value);
    } else if (threeDateField) {
      const day = this.dobFieldTargets[0].value || '';
      const month = this.dobFieldTargets[1].value || '';
      const year = this.dobFieldTargets[2].value || '';
      if (day && month && year) {
        const dob = new Date(`${day}/${month}/${year}`);
        birthdayToDate = dob;
      }
    }

    return birthdayToDate;
  }

  static translateYears(year) {
    if (year === 1) {
      return I18n.messages.display_age.year.one.replace('%{age_year}', year);
    }

    return I18n.messages.display_age.year.other.replace('%{age_year}', year);
  }

  static translateMonths(month) {
    if (month === 1) {
      return I18n.messages.display_age.month.one.replace('%{age_month}', month);
    }

    return I18n.messages.display_age.month.other.replace('%{age_month}', month);
  }

  calculateAgeYears() {
    const dob = this.birthdayToDate();
    const today = new Date();
    const ageInYears = moment(today).diff(dob, 'years', true);
    return parseInt(Math.floor(ageInYears), 10);
  }

  getMonthsRemainder() {
    const dob = this.birthdayToDate();
    const today = new Date();
    const ageMonthsDiff = moment(today).diff(dob, 'months', true);
    const ageInMonths = parseInt(Math.floor(ageMonthsDiff), 10);
    const monthsRemainder = ageInMonths % MONTHS_IN_A_YEAR;
    return monthsRemainder;
  }

  setAgeField() {
    const ageYears = this.calculateAgeYears();
    const ageMonths = this.getMonthsRemainder();
    const threeDateField = this.dobFieldTargets.length === 3;

    if (threeDateField) {
      this.setAgeInThreeDateField(ageMonths, ageYears);
    } else {
      const years = this.constructor.translateYears(ageYears);
      const months = this.constructor.translateMonths(ageMonths);
      this.ageFieldTarget.value = `${years} ${months}`;
    }
  }

  setAgeInThreeDateField(ageMonths, ageYears) {
    const day = this.dobFieldTargets[0].value || '';
    const month = this.dobFieldTargets[1].value || '';
    const year = this.dobFieldTargets[2].value || '';

    if (day && month && year) {
      const years = this.constructor.translateYears(ageYears);
      const months = this.constructor.translateMonths(ageMonths);
      this.ageFieldTarget.value = `${years} ${months}`;
    } else {
      this.ageFieldTarget.value = '';
    }
  }
}
