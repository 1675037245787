import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field', 'otherTribalAffiliation'];

  connect() {
    this.toggleOtherTribalAffiliation();
  }

  toggleOtherTribalAffiliation() {
    if (this.hasFieldTarget) {
      const shouldDisplay = this.fieldTarget.value === 'Other';

      $(this.otherTribalAffiliationTarget).toggle(shouldDisplay);
    }
  }
}
