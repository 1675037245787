$(document).on('turbolinks:load', () => {
  $('#supply_inventory_information_sheet_file_button').click(() => {
    $("input[type='file'][id='supply_inventory_information_sheet']").trigger('click');
  });

  $("input[type='file'][id='supply_inventory_information_sheet']").change((e) => {
    if (e.target.value) {
      $('#supply_inventory_information_sheet_file_val').text(e.target.value.replace(/C:\\fakepath\\/i, ''));
    } else {
      $('#supply_inventory_information_sheet_file_val').text('No File Chosen');
    }
  });
});
