/* eslint-disable no-param-reassign */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'section', 'secondaryInsuranceRadio', 'field', 'cardField', 'insuranceType', 'insuranceCardNumberField',
    'memberIDNumberField', 'companyNameField', 'otherCompanyNameField', 'otherCompanyNameContainer'];

  connect() {
    this.toggleSecondaryInsuranceSection();
    this.validateInsuranceCardNumberField();
    this.validateGroupNumberField();
    this.validateOptionalField();
  }

  toggleSecondaryInsuranceSection() {
    const shouldDisplay = $(this.secondaryInsuranceRadioTarget).is(':checked');

    $(this.sectionTarget).toggle(shouldDisplay);

    this.cardFieldTargets.forEach((field) => {
      const fieldValidationsTarget = field.dataset.validationsTarget;
      if (fieldValidationsTarget && !fieldValidationsTarget.includes('optionalField')) {
        $(field).attr('required', $(field).data('cardAttached') && shouldDisplay);
      }
    });

    this.insuranceTypeTarget.disabled = !shouldDisplay;

    this.validateInsuranceCardNumberField();
    this.validateGroupNumberField();
    this.validateOptionalField();
  }

  validateInsuranceCardNumberField() {
    // eslint-disable-next-line max-len
    if (this.hasInsuranceTypeTarget && this.hasInsuranceCardNumberFieldTarget && this.hasMemberIDNumberFieldTarget) {
      const required = this.insuranceTypeTarget.value === 'Medicaid/Medical Assistance';
      const noInsurance = this.insuranceTypeTarget.value === 'No Insurance';

      if (noInsurance) {
        this.insuranceCardNumberFieldTarget.disabled = true;
        this.memberIDNumberFieldTarget.disabled = true;
      } else {
        this.insuranceCardNumberFieldTarget.disabled = false;
        this.memberIDNumberFieldTarget.disabled = false;

        $(this.insuranceCardNumberFieldTarget).attr('required', required);
        $(this.cardAssistanceLabelTarget).toggle(required);

        $(this.memberIDNumberFieldTarget).attr('required', required);
        $(this.memberIDNumberLabelTarget).toggle(required);
      }
    }
  }

  validateGroupNumberField() {
    const shouldDisplay = $(this.secondaryInsuranceRadioTarget).is(':checked');

    if (shouldDisplay) {
      this.fieldTargets.forEach((field) => {
        if (field.dataset.validationsTarget.includes('groupNumberField')) {
          field.required = false;
        }
      });
    }
  }

  validateOptionalField() {
    const shouldDisplay = $(this.secondaryInsuranceRadioTarget).is(':checked');

    if (shouldDisplay) {
      this.fieldTargets.forEach((field) => {
        if (field.dataset.validationsTarget.includes('optionalField')) {
          field.required = false;
        }
      });
    }
  }
}
