import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'userCheckbox',
    'bulkDeactivateModal',
    'bulkDeactivateQuantity',
    'bulkDeactivateUsersButton',
    'submit',
    'selectAllCheckbox',
  ];

  static values = {
    count: Number,
  };

  bulkDeactivate() {
    this.submitTarget.click();
  }

  toggleConfirmationModal(event) {
    event.preventDefault();

    this.bulkDeactivateQuantityTarget.innerHTML = this.selectedUsersCount();
    this.bulkDeactivateModalTarget.classList.toggle('hidden');
  }

  enableDeactivateUsersButton() {
    if (this.selectedUsersCount() > 0) {
      this.bulkDeactivateUsersButtonTarget.removeAttribute('disabled');
    } else {
      this.bulkDeactivateUsersButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  selectedUsersCount() {
    if (this.hasSelectAllCheckboxTarget && this.selectAllCheckboxTarget.checked) {
      return this.countValue;
    }
    return this.userCheckboxTargets.filter((checkbox) => checkbox.checked).length;
  }
}
