import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['accordion', 'symbol'];

  toggleItemTable() {
    const tbody = $(this.accordionTarget.nextElementSibling);
    const symbol = $(this.symbolTarget);
    if (tbody.is(':hidden')) {
      symbol.removeClass('fa-plus').addClass('fa-minus').attr('title', 'Collapse table');
      tbody.slideDown('fast');
    } else {
      symbol.removeClass('fa-minus').addClass('fa-plus').attr('title', 'Expand table');
      tbody.slideUp('fast');
    }
  }
}
