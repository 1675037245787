import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'trigger'];

  handleClick() {
    this.triggerTarget.classList.add('hidden');
    this.triggerTarget.classList.remove('button-action');
    this.formTarget.classList.remove('hidden');
  }
}
