import { Controller } from '@hotwired/stimulus';
/* global I18n */

export default class extends Controller {
  static targets = ['error', 'input'];

  cleanErrors() {
    const isInputInvalid = this.inputTarget.classList.contains('is-error');
    const hideError = isInputInvalid && this.errorTarget;

    if (hideError) {
      this.errorTarget.classList.add('hidden');
      this.inputTarget.classList.remove('is-error');
    }
  }

  handleInvalid(event) {
    event.preventDefault();
    this.addError();
    this.focusOnFirstInvalid();
  }

  focusOnFirstInvalid() {
    const withFocus = document.querySelectorAll(".text-field-group > input[type='text']:focus");
    if (!withFocus.length) {
      this.inputTarget.focus();
    }
  }

  addError() {
    this.createErrorElement();
    this.inputTarget.classList.add('is-error');
    this.addErrorText();
    this.errorTarget.classList.remove('hidden');
  }

  createErrorElement() {
    if (!this.hasErrorTarget) {
      const errorElement = document.createElement('div');
      errorElement.classList.add('text-red-600', '-mt-2', 'text-xs', 'mb-4');
      errorElement.setAttribute('data-field-group-target', 'error');
      this.inputTarget.after(errorElement);
    }
  }

  addErrorText() {
    const attributeName = this.inputTarget.getAttribute('aria-label');
    const errorMessage = this.getErrorMessage(attributeName);
    this.errorTarget.innerHTML = errorMessage;
  }

  getErrorMessage(attributeName) {
    if (this.inputTarget && this.inputTarget.dataset.customErrorMessage) {
      return this.inputTarget.dataset.customErrorMessage;
    }
    try {
      const fieldName = attributeName.replace(/_id/g, '').replace(/_/g, ' ').toLowerCase();
      let errorMessage = null;

      if (!fieldName.match(/^upload/)) {
        errorMessage = I18n.errors.fill_out_field.replace('%{field}', fieldName);
      } else {
        errorMessage = I18n.errors.upload_field.replace('%{field}', fieldName);
      }

      return errorMessage;
    } catch {
      return I18n.errors.fill_out_default_field;
    }
  }
}
