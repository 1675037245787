import FieldGroupController from './field_group_controller';

export default class extends FieldGroupController {
  cleanErrors() {
    const isInputInvalid = this.chosenContainer().classList.contains('is-error');
    const hideError = isInputInvalid && this.errorTarget;

    if (hideError) {
      this.errorTarget.classList.add('hidden');
      this.chosenContainer().classList.remove('is-error');
    }
  }

  addError() {
    this.createErrorElement();
    this.chosenContainer().classList.add('is-error');
    this.addErrorText();
    this.errorTarget.classList.remove('hidden');
  }

  createErrorElement() {
    if (!this.hasErrorTarget) {
      const errorElement = document.createElement('div');
      errorElement.classList.add('text-red-600', '-mt-2', 'text-xs', 'mb-4', 'inline-block');
      errorElement.setAttribute('data-field-chosen-group-target', 'error');
      this.chosenContainer().after(errorElement);
    }
  }

  /* eslint-disable class-methods-use-this */
  focusOnFirstInvalid() {
    document.querySelectorAll('.text-field-group > .chosen-container.is-error')[0].scrollIntoView();
  }
  /* eslint-enable class-methods-use-this */

  chosenContainer() {
    return this.element.querySelector('.chosen-container');
  }
}
