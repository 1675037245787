import { Controller } from '@hotwired/stimulus';
import { Tabs } from 'flowbite';

export default class extends Controller {
  static values = { autoShowSingleVaccinatorTab: Boolean };

  static targets = ['singleVaccinatorTab', 'multiVaccinatorTab', 'singleVaccinatorTabContent', 'multiVaccinatorTabContent'];

  connect() {
    this.initTabs();
  }

  initTabs() {
    const tabElements = [
      {
        id: 'single-vaccinator-tab',
        triggerEl: this.singleVaccinatorTabTarget,
        targetEl: this.singleVaccinatorTabContentTarget,
      },
      {
        id: 'multi-vaccinator-tab',
        triggerEl: this.multiVaccinatorTabTarget,
        targetEl: this.multiVaccinatorTabContentTarget,
      },
    ];

    const options = {
      onShow: (tabs) => {
        // eslint-disable-next-line no-underscore-dangle
        if (tabs._activeTab.id === 'single-vaccinator-tab') {
          this.singleVaccinatorTabContentTarget.disabled = false;
          this.multiVaccinatorTabContentTarget.disabled = true;
        } else {
          this.singleVaccinatorTabContentTarget.disabled = true;
          this.multiVaccinatorTabContentTarget.disabled = false;
        }
      },
    };

    const tabs = new Tabs(this.element, tabElements, options);

    if (this.autoShowSingleVaccinatorTabValue) {
      tabs.show('single-vaccinator-tab');
    } else {
      tabs.show('multi-vaccinator-tab');
    }
  }
}
