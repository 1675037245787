/* global I18n */
/* eslint-disable no-alert */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['email', 'phone', 'emailMethod', 'phoneMethod', 'authCode'];

  intiateAuthentication() {
    const email = this.emailTarget.value;
    const phone = this.phoneTarget.value;
    let validEmail = true;

    if (this.emailMethodTarget.checked && email !== '') {
      const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*?/;
      validEmail = (emailRegex.test(email));

      if (validEmail === false) {
        alert(I18n.errors.invalid_email);
        return (false);
      }
    }

    if (this.emailMethodTarget.checked && email === '') {
      alert(I18n.errors.email_required);
      return false;
    }

    if (this.phoneMethodTarget.checked && phone === '') {
      alert(I18n.errors.phone_number_required);
      return false;
    }

    $('#authentication').removeClass('hidden');
    return true;
  }

  sendAuthCode() {
    const email = this.emailTarget.value;
    const phone = this.phoneTarget.value;
    const method = this.emailMethodTarget.checked ? 'email' : 'phone';

    if (this.emailMethodTarget.checked === false && this.phoneMethodTarget.checked === false) {
      alert(I18n.errors.method_required);
      return false;
    }

    $.ajax({
      // eslint-disable-next-line max-len
      url: `/registration/public/patients/send_otp/?email=${email}&phone_number=${phone}&method=${method}`,
      method: 'POST',
      dataType: 'JSON',
      success() {
        $('#authentication').addClass('hidden');
        $('#verification').removeClass('hidden');
      },
      error(jqXHR) {
        const error = JSON.parse(jqXHR.responseText);
        $('#error-container').html(`<p class="error-message">${error.message}</p>`);
      },
    });

    return true;
  }

  verifyAuthCode() {
    const code = this.authCodeTarget.value;

    if (code) {
      $.ajax({
        url: `/registration/public/patients/verify_via_auth_code/?code=${code}`,
        method: 'GET',
        success() {
          $('#message').html(`<p>${I18n.messages.continue}</p>`);
          $('#authenticate').addClass('invisible');
          $('#submitButton').removeClass('invisible');
          setTimeout(() => {
            $('#patient-verification').remove();
          }, 5000);
        },
        error() {
          $('#message').html(`<p class="error-message">${I18n.errors.invalid_token}</p>`);
        },
      });
    } else {
      alert(I18n.errors.code_required);
    }
  }
}
