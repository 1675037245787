function bindChosenSelect() {
  $('select').on('chosen:ready', (e) => {
    const { target } = e;
    const height = $(target).next('.chosen-container').height();
    const width = $(target).next('.chosen-container').width();

    $(target).css({
      position: 'absolute',
      height,
      width,
      opacity: 0,
    }).show();
  });

  $('.chosen-select').chosen().change((e) => {
    if (e.target.id !== 'patient_insurance_company_name' && e.target.id !== 'patient_secondary_insurance_company_name') { return; }

    if (e.bubbles) {
      e.preventDefault();
      return;
    }

    const event = new Event('change', { bubbles: true });
    e.target.dispatchEvent(event);
  });
}

$(document).on('turbolinks:load', () => {
  bindChosenSelect();
});
