import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['displayText', 'otpInput', 'otpErrorLabel', 'submitBtn', 'backBtn', 'resendText'];

  handleSuccess = () => {
    this.otpInputTarget.classList.remove('border-red-500');
    this.otpInputTarget.classList.add('border-green-500');
    this.otpInputTarget.disabled = true;
    this.otpErrorLabelTarget.classList.add('!hidden');
    this.submitBtnTarget.classList.add('!hidden');
    this.displayTextTarget.classList.add('!hidden');
    this.backBtnTarget.classList.remove('!hidden');

    const exportFileId = window.location.pathname.split('/')[2];
    window.location.href = `/export_files/${exportFileId}/downloads/download`;
    this.resendTextTarget.classList.add('!hidden');

    setTimeout(() => {
      this.backBtnTarget.removeAttribute('disabled');
    }, 1000);
  };

  handleError = () => {
    this.otpInputTarget.classList.add('border-red-500');
    this.otpErrorLabelTarget.classList.remove('!hidden');
  };
}
