/* eslint-disable max-len */

import EnterpriseClinicInventorySearchController from './enterprise_clinic_inventory_search_controller';

// This controller is par of the Enterprise Clinic Inventory Search
// (clinic_inventory_search flag) feature. This feature is built using
// three stimulus controllers:
// - EnterpriseClinicInventoryController
// - EnterpriseClinicInventorySearchController
// - EnterpriseClinicInventorySearchWithResultsController
//
// This controller(EnterpriseClinicInventorySearchWithResultsController) inherits
// from EnterpriseClinicInventorySearchController so it has the search
// functionality as well (request to backend and process the response). This
// controller is used to show the results of the typed keywords. It also handles
// search again. The popup that uses this controller renders the search
// functionality, that's why we inherit from EnterpriseClinicInventorySearchController.
//
// Once a user selects any of the supply inventories shown in the list, this controller
// dispatches the addSupplyInventory event with the selected supply inventory. The
// addSupplyInventory event is read by the EnterpriseClinicInventoryController.
//
// NOTE: to knmow more about how the feature works, look at the
// EnterpriseClinicInventoryController which has an overall description of the feature,
// and EnterpriseClinicInventorySearchController for some more notes.

export default class extends EnterpriseClinicInventorySearchController {
  static targets = [
    'searchField', 'modal', 'noInventoryQuery', 'noInventorySection', 'withInventorySection',
    'totalFound', 'addItemButton', 'tableContainer'];

  static classes = ['hidden', 'selected'];

  static values = ['supplyInventories', 'selectedSupplyInventory'];

  showResults(event) {
    const { query, supplyInventories } = event.detail;
    this.setQueryInSearchField(query);
    this.resetSelectedSupplyInventory();
    this.disableAddItemButton();

    if (supplyInventories?.length > 0) {
      this.storeSupplyInventories(supplyInventories);
      this.showInventory(supplyInventories);
    } else {
      this.showNoInventory(query);
    }

    this.modalTarget.classList.remove(this.hiddenClass);
  }

  setQueryInSearchField(query) {
    this.searchFieldTarget.value = query;
  }

  resetSelectedSupplyInventory() {
    this.selectedSupplyInventoryValue = null;
  }

  disableAddItemButton() {
    this.addItemButtonTarget.setAttribute('disabled', 'disabled');
  }

  storeSupplyInventories(supplyInventories) {
    this.supplyInventoriesValue = supplyInventories;
  }

  showInventory(supplyInventories) {
    let inventoryRows = '';
    supplyInventories.forEach((supplyInventory) => {
      inventoryRows += `
        <tr data-action="click->enterprise-clinic-inventory-search-with-results#selectSupplyInventory"
          data-supply-inventory-id="${supplyInventory.id}" class="cursor-pointer">
          <td>${supplyInventory.name}</td>
          <td>${supplyInventory.lot_number}</td>
          <td>${supplyInventory.expiration_date}</td>
          <td>${supplyInventory.source}</td>
          <td>${supplyInventory.created_by}</td>
          <td>${supplyInventory.organization}</td>
          <td>${supplyInventory.venue}</td>
          <td>${supplyInventory.county}</td>
        </tr>
      `;
    });

    this.scrollToTopResultsContainer();
    this.withInventorySectionTarget.querySelector('table > tbody').innerHTML = inventoryRows;
    this.totalFoundTarget.innerHTML = supplyInventories.length;
    this.withInventorySectionTarget.classList.remove(this.hiddenClass);
    this.noInventorySectionTarget.classList.add(this.hiddenClass);
  }

  showNoInventory(query) {
    this.noInventoryQueryTarget.innerHTML = query;
    this.noInventorySectionTarget.classList.remove(this.hiddenClass);
    this.withInventorySectionTarget.classList.add(this.hiddenClass);
  }

  selectSupplyInventory(event) {
    const clickedSupplyInventory = event.currentTarget;
    const clickedSupplyInventoryId = clickedSupplyInventory.dataset.supplyInventoryId;

    clickedSupplyInventory.parentElement.querySelectorAll('tr').forEach((row) => row.classList.remove(this.selectedClass));
    clickedSupplyInventory.classList.add(this.selectedClass);

    // eslint-disable-next-line arrow-body-style
    this.selectedSupplyInventoryValue = this.supplyInventoriesValue.find((supplyInventory) => {
      return supplyInventory.id === parseInt(clickedSupplyInventoryId, 10);
    });

    this.addItemButtonTarget.removeAttribute('disabled');
  }

  addSupplyInventory() {
    this.dispatch('addSupplyInventory', { detail: { supplyInventory: this.selectedSupplyInventoryValue } });
    this.closeModal();
  }

  scrollToTopResultsContainer() {
    this.tableContainerTarget.scrollTop = 0;
  }

  closeModal() {
    this.dispatch('enterpriseSearchResultsModalClosed');
    this.resetSelectedSupplyInventory();
    this.disableAddItemButton();
    this.scrollToTopResultsContainer();
    this.modalTarget.classList.add(this.hiddenClass);
  }
}
