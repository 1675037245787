import { Controller } from '@hotwired/stimulus';

export default class timeout extends Controller {
  static targets = ['sessionExpiryModal'];

  connect() {
    const timer = parseInt(this.data.get('sessionActiveThreshold'), 10);
    const enabled = this.data.get('sessionEnabled');
    const that = this;

    if (enabled === 'true') {
      setTimeout(() => {
        that.sessionExpiryModalTarget.classList.toggle('hidden');
      }, timer * 1000);
    }
  }
}
