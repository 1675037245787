import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static outlets = ['simple-message-modal--component'];

  static values = { forecastVaccinePath: String };

  openModal(event) {
    event.preventDefault();
    this.simpleMessageModalComponentOutlet.setBodyText('<div class="text-center"=><i class="fas fa-spinner fa-spin text-5xl"></i></div>');
    this.simpleMessageModalComponentOutlet.showModal();

    this.populateModal();
  }

  populateModal() {
    $.ajax({
      url: this.forecastVaccinePathValue,
      type: 'html',
      method: 'GET',
      success: (data) => {
        this.simpleMessageModalComponentOutlet.setBodyText(data);
      },
      error: () => {
        this.simpleMessageModalComponentOutlet.setBodyText('Something went wrong, please try again later');
      },
    });
  }
}
