/* eslint-disable no-use-before-define */
$(document).on('turbolinks:load', () => {
  closedClinicModal();
  $('.collect-insurance-text').hide();
  collectInsuranceText();
  $('.collect-insurance').on('change', () => {
    collectInsuranceText();
  });
  $('.patient-disabled').css({
    'pointer-events': 'none',
    'background-color': 'lightgrey',
  });
});

function collectInsuranceText() {
  if ($('.collect-insurance:checked').val() === 'no') {
    $('.collect-insurance-text').show();
  } else {
    $('.collect-insurance-text').hide();
  }
}

function closedClinicModal() {
  $('.clinic-event-radio').click((event) => {
    if (!$('.clinic-events-page').data('clinicClosed')) { return; }

    event.preventDefault();
    $('.cancel-clinic-modal').toggleClass('hidden');
  });
}
