import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class extends Controller {
  static targets = ['element'];

  connect() {
    this.elementTargets.forEach((element) => {
      tippy(`#${element.id}`, {
        content: element.dataset.content,
      });
    });
  }
}
