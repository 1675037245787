import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['vaccineListItem'];

  static values = {
    vaccineFamiliesSortOrder: { type: String, default: 'asc' },
  };

  sortVaccines(event) {
    event.preventDefault();
    const isAsc = (this.vaccineFamiliesSortOrderValue === 'asc');
    const order = isAsc ? 1 : -1;
    this.vaccineFamiliesSortOrderValue = isAsc ? 'desc' : 'asc';

    [...this.vaccineListItemTargets]
      .sort((a, b) => (a.dataset.vaccineName > b.dataset.vaccineName ? order : -order))
      .forEach((item) => this.vaccineListItemTarget.parentElement.appendChild(item));
  }
}
