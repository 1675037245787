import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

// This controller is par of the Enterprise Clinic Inventory Search
// (clinic_inventory_search flag) feature. This feature is built using
// three stimulus controllers:
// - EnterpriseClinicInventoryController
// - EnterpriseClinicInventorySearchController
// - EnterpriseClinicInventorySearchWithResultsController
//
// At the beginning, only the views for the first two controllers are visible.
// The EnterpriseClinicInventorySearchController is used to handle the search
// widget. This widget sends a request to the backend for the typed keywords and
// dispatches an event (resultsReady) with the inventory supplies that matched the
// keywords and were returned by the backend. The resultsReady event is read by
// the EnterpriseClinicInventorySearchWithResultsController and it uses the
// supply inventories returned with the event to show them in a dialog box where
// users can select whatever they want.
//
// After a user selects a supply inventory from the popup, the
// EnterpriseClinicInventorySearchWithResultsController dispatches the addSupplyInventory
// event with the selected supply inventory. Then the EnterpriseClinicInventoryController
// catches the event and process it. It's there where this controller you're reading
// the documentaion on starts its journey.
//
// This controller after receiving the selected supply inventory. It runs some
// validations, if any of them is invalid, a popup is shown. Some popups let you
// continue with the selected supply environment, some do not. That depends on
// some feature flags being enabled/disabled.
//
// After running validations, the supply inventory could end up in the Clinic Inventory
// table as part of a valid supply inventory to be added to the clinic.

export default class extends Controller {
  static targets = [
    'expiredModal', 'allowExpiredModal', 'expirationLink', 'organizationVenueDoNotMatchModal',
    'selectedVenue', 'selectedOrganization', 'clinicSupplyInventory',
  ];

  static values = ['supplyInventoryToBeAdded', 'expiredInventoryAllowed'];

  static classes = ['hidden'];

  // Fired when addSupplyInventory event is fired. It's fired from the modal dialog
  // where you can search for inventories.
  addSupplyInventory(event) {
    const { supplyInventory } = event.detail;

    // Set it here to avoid passing the supplyInventory through many methods.
    // From now on, supplyInventoryToBeAddedValue has the inventory to be added.
    this.supplyInventoryToBeAddedValue = supplyInventory;

    if (this.invalidScenarios()) return;

    this.addInventory();
  }

  // There's a dialog that appears indicating the venue and/or org do not match
  // to the ones assigned to the inventory. This method adds the inventory if
  // the users chose to add inventory anyways.
  addInventoryWhenOrganizationAndVenueDontMatch() {
    this.addInventory();
    this.organizationVenueDoNotMatchModalTarget.classList.add(this.hiddenClass);
  }

  // There's a dialog that appears if the allow_assigning_expired_inventory flag
  // is on. People can add inventory even if it's expired.
  allowExperiedInventory() {
    this.expiredInventoryAllowedValue = true;
    this.hideAllowExpiredModal();

    if (this.invalidScenarios()) {
      this.resetExpiredInventoryAllowed();
      return;
    }

    this.resetExpiredInventoryAllowed();
    this.addInventory();
  }

  // Method to add the inventory to the inventory table after being selected and
  // passed through all filters(validations).
  addInventory() {
    const { clinicId } = this.element.dataset;
    const inventoryId = this.supplyInventoryToBeAddedValue.id;

    // Make a request and let rails update the table. This is to avoid having two
    // places where items can be added to the table. The reason being that if the
    // table's structure is changed in the rails partial, someone might forget to
    // update the code if we were adding it here in the controller.
    // If we ever want to add it from here, then we have access to the inventory
    // through this.supplyInventoryToBeAddedValue
    Rails.ajax({
      type: 'get',
      url: `/supply_inventories/${inventoryId}/add_to_clinic?clinic_id=${clinicId}`,
      dataType: 'script',
    });
  }

  // Runs validations and returns true if any of them is invalid.
  invalidScenarios() {
    return this.supplyInventoryAlreadyAdded()
      || this.inventoryExpired()
      || this.inventoryExpiredAndNotAllowed()
      || this.organizationVenueDoNotMatch();
  }

  // Shows an alret and returns true if the supply inventory to be added is already
  // added in the inventory table.
  supplyInventoryAlreadyAdded() {
    const existingSupplyInventoryIds = [];

    const inventoryAllocations = document.querySelector('#clinic_allocated_inventories')
      .querySelectorAll('tr');
    inventoryAllocations.forEach((row) => {
      existingSupplyInventoryIds.push(parseInt(row.dataset.id, 10));
    });

    const alreadyAdded = existingSupplyInventoryIds.includes(
      parseInt(this.supplyInventoryToBeAddedValue.id, 10),
    );

    if (alreadyAdded) {
      setTimeout(() => {
        alert("You've already added this item to the clinic.");
      }, 100);
    }

    return alreadyAdded;
  }

  // Shows expired inventory dialog and returns true if supply inventory to be
  // added has already expired. The dialog shown here is the one that does not
  // let users proceed with the supply inventory when it's expired.
  inventoryExpired() {
    if (this.hasExpiredModalTarget) {
      const hasExpired = this.hasInventoryExpired();

      if (hasExpired) this.showExpirationModal(this.expiredModalTarget);

      return hasExpired;
    }

    return false;
  }

  // Shows expired inventory dialog and returns true if supply inventory to be
  // added has already expired. The dialog shown here is the one that allows
  // users to proceed with the supply inventory even if it's expired.
  inventoryExpiredAndNotAllowed() {
    if (this.hasAllowExpiredModalTarget) {
      const expiredAndNotAllowed = this.hasInventoryExpired() && !this.expiredInventoryAllowedValue;

      if (expiredAndNotAllowed) this.showExpirationModal(this.allowExpiredModalTarget);

      return expiredAndNotAllowed;
    }

    return false;
  }

  // Shows a dialog telling organization and/or venue do not match and returns
  // true if organization and/or venue do not match with the one set in the
  // supply inventory to be added. This dialog does allow people to proceed
  // selecting the supply inventory if they want to.
  organizationVenueDoNotMatch() {
    const venueId = this.supplyInventoryToBeAddedValue.venue_id;
    const organizationId = this.supplyInventoryToBeAddedValue.organization_id;

    const doNotMatch = venueId !== parseInt(this.selectedVenueTarget.value, 10)
      || organizationId !== parseInt(this.selectedOrganizationTarget.value, 10);

    if (doNotMatch) {
      this.organizationVenueDoNotMatchModalTarget.classList.remove(this.hiddenClass);
    }

    return doNotMatch;
  }

  // Removes supply inventory already aded to the clinic. This just removes it
  // from the html table. The real removal will happen once the clinic is saved.
  removeSupplyInventory(event) {
    const supplyInventoryIdToRemove = event.currentTarget.dataset.id;
    // Prefer disabling the rule so we don't have a long one single line of code.
    // eslint-disable-next-line arrow-body-style
    const supplyInventoryToRemove = this.clinicSupplyInventoryTargets.find((inventory) => {
      return inventory.dataset.id === supplyInventoryIdToRemove;
    });

    if (supplyInventoryToRemove) supplyInventoryToRemove.remove();
  }

  hasInventoryExpired() {
    if (this.supplyInventoryToBeAddedValue.expiration_date === null) return false;

    const [month, day, year] = this.supplyInventoryToBeAddedValue.expiration_date.split('/');
    // JS counts months starting from 0 up to 11
    const expirationDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));

    return expirationDate < yesterday;
  }

  hideAllowExpiredModal() {
    this.hideModal(this.allowExpiredModalTarget);
  }

  hideOrganizationVenueDoNotMatchModal() {
    this.hideModal(this.organizationVenueDoNotMatchModalTarget);
  }

  hideExpiredModal() {
    this.hideModal(this.expiredModalTarget);
  }

  hideModal(modalTarget) {
    modalTarget.classList.add(this.hiddenClass);
  }

  showExpirationModal(modalTarget) {
    modalTarget.classList.remove(this.hiddenClass);
    this.expirationLinkTarget.href = this.supplyInventoryToBeAddedValue.expiration_url;
  }

  resetExpiredInventoryAllowed() {
    this.expiredInventoryAllowedValue = false;
  }
}
