/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [''];

  printContent() {
    window.print();
  }

  addVaccineDetails(e) {
    const time = new Date().getTime();
    const regexp = new RegExp($(e.currentTarget).data('id'), 'g');
    $(e.currentTarget).closest('.vaccinated').find('tbody').append($(e.currentTarget).data('fields').replace(regexp, time));
    return event.preventDefault();
  }

  removeVaccineDetails(e) {
    $(e.currentTarget).prev('input[type=hidden]').val(!e.currentTarget.checked);
  }
}
