let pollingTimer;

$(document).on('turbolinks:load', () => {
  if ($('#report-data').data('url') !== undefined) {
    if (pollingTimer) {
      clearTimeout(pollingTimer);
    }
    pollingTimer = setInterval(() => {
      if ($('#report-data').data('url')) {
        $.ajax($('#report-data').data('url'));
      }
    }, 2000);
  }
});
