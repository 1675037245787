/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal', 'clinicStaffFields', 'testKitsTable', 'form', 'locationModal', 'vaccine', 'productName', 'manufacturer', 'itemType', 'ndcCode', 'clinic', 'eventDate', 'eventType',
    'doseVolume', 'visDate', 'volumeUnits', 'volumeUnitsRequired', 'cptCode', 'icd10Code', 'npiNumber'];

  connect() {
    this.populateDetails();
    this.toggleVaccineFields();
  }

  populateClinicDetails() {
    const clinicId = $(this.clinicTarget).val();
    const eventDate = $(this.eventDateTarget);
    const eventType = $(this.eventTypeTarget);

    if (clinicId) {
      $.ajax({
        url: `/clinics/${clinicId}/fetch_details`,
        method: 'GET',
        success: (options) => {
          eventDate.attr('disabled', false);
          eventType.attr('disabled', false);
          if (options.setting) {
            eventType.val(options.setting);
            eventType.prop('disabled', true);
            $(eventType).next('input').val($(eventType).val());
          } else {
            eventType.val('');
          }
          if (options.clinic_date) {
            eventDate.val(options.clinic_date);
            $(eventDate).next('input').attr('disabled', true);
            $(eventDate).next('input').val($(eventDate).val());
          }
          eventDate.removeClass('invalid');
          eventType.removeClass('invalid');
        },
      });
    } else {
      eventType.prop('disabled', false);
      eventType.val('');
      $(eventType).next('input').val($(eventType).val());
      $(eventDate).next('input').attr('disabled', false);
      $(eventDate).next('input').val('');
    }
  }

  preventNegativeNumber(event) {
    if ((event.key === '-') || (event.key === '+') || (event.key === '.') || (event.key === 'e')) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  formValidity(event) {
    const isValid = this.validateForm(this.formTarget);

    // If the form is invalid, prevent default on the event
    if (!isValid) {
      event.preventDefault();
    } else {
      this.toggleModal();
    }
  }

  validateForm() {
    let isValid = true;

    // Find required fields
    const requiredFieldSelectors = ':required';
    const requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);

    requiredFields.forEach((field) => {
      field.classList.remove('invalid');

      // For each required field, check to see if the value is empty
      if (!field.disabled && !field.value.trim()) {
        field.classList.add('invalid');
        field.parentElement.querySelector('.error-message').classList.remove('hidden');

        isValid = false;
      }
    });

    return isValid;
  }

  validateInputField(event) {
    // Find the error message element
    const errorMessage = event.target.parentNode.getElementsByClassName('error-message')[0];

    // Display error message if value of the input is empty
    if (event.target.value === '') {
      event.target.classList.add('invalid');
      errorMessage.classList.remove('hidden');
    } else {
      event.target.classList.remove('invalid');
      errorMessage.classList.add('hidden');
    }
  }

  toggleModal() {
    const modal = document.getElementById('InventoryNewEntryModal');
    modal.classList.toggle('opacity-0');
    modal.classList.toggle('pointer-events-none');
  }

  toggleUpdateModal() {
    const modal = document.getElementById('InventoryUpdateEntryModal');
    modal.classList.toggle('opacity-0');
    modal.classList.toggle('pointer-events-none');
  }

  updateModalField(e) {
    const currentFieldId = e.currentTarget.id;
    const currentFieldValue = e.currentTarget.value;

    if (document.getElementById(`modal_${currentFieldId}`)) {
      document.getElementById(`modal_${currentFieldId}`).value = currentFieldValue;
    }
  }

  updateModalCheckboxField(e) {
    const currentFieldId = e.currentTarget.id;

    if (e.currentTarget.checked) {
      document.getElementById(`modal_${currentFieldId}`).checked = true;
    } else {
      document.getElementById(`modal_${currentFieldId}`).checked = false;
    }
  }

  updateUnitOfMeasureRequired(e) {
    const isValuePresent = e.target.value && e.target.value !== '0';
    this.volumeUnitsTarget.dataset.required = isValuePresent;
    this.volumeUnitsTarget.required = isValuePresent;

    if (!isValuePresent) {
      this.volumeUnitsRequiredTarget.setAttribute('hidden', true);
    } else {
      this.volumeUnitsRequiredTarget.removeAttribute('hidden');
    }
  }

  onLocationModal(e) {
    if (e.currentTarget.dataset.value === 'open') {
      this.locationModalTarget.classList.remove('hidden');
    } else {
      this.locationModalTarget.classList.add('hidden');
    }
  }

  populateDetails() {
    const id = this.hasVaccineTarget ? $(this.vaccineTarget).val() : null;
    const productName = this.hasProductNameTarget ? $(this.productNameTarget) : null;
    const manufacturer = this.hasManufacturerTarget ? $(this.manufacturerTarget) : null;
    const ndcCode = this.hasNdcCodeTarget ? $(this.ndcCodeTarget) : null;
    const itemType = this.hasItemTypeTarget ? $(this.itemTypeTarget) : null;
    const doseVolume = this.hasDoseVolumeTarget ? $(this.doseVolumeTarget) : null;
    const volumeUnits = this.hasVolumeUnitsTarget ? $(this.volumeUnitsTarget) : null;
    const cptCode = this.hasCptCodeTarget ? $(this.cptCodeTarget) : null;
    const icd10Code = this.hasIcd10CodeTarget ? $(this.icd10CodeTarget) : null;
    const npiNumber = this.hasNpiNumberTarget ? $(this.npiNumberTarget) : null;
    const visDate = this.hasVisDateTarget ? $(this.visDateTarget) : null;

    if (id) {
      $.ajax({
        url: `/vaccines/${id}/fetch_data`,
        method: 'GET',
        success: (options) => {
          itemType.val(itemType.data('selected'));
          // eslint-disable-next-line max-len
          enableFields(productName, manufacturer, itemType, ndcCode, doseVolume, visDate, volumeUnits, cptCode, npiNumber, icd10Code);
          if (options.name) {
            productName.val(options.name);
            productName.attr('readonly', true);
          }
          if (options.manufacturer_id) {
            $.ajax({
              type: 'GET',
              url: `/manufacturers/${options.manufacturer_id}/fetch_detail`,
            }).then((data) => {
              const option = new Option(data.name, data.id, true, true);
              $('#manufacturers-select2').append(option).trigger('change');
            });

            manufacturer.attr('disabled', true);
            $('input[id=supply_inventory_manufacturer_id]').val(options.manufacturer_id);
          }
          if (options.ndc_code && ndcCode) {
            ndcCode.val(options.ndc_code);
            ndcCode.attr('readonly', true);
          }
          if (options.dose_volume && doseVolume) {
            doseVolume.val(options.dose_volume);
            doseVolume.attr('readonly', true);
          }

          if (options.vis_date && visDate) {
            visDate.val(options.vis_date);
            visDate.next('input').attr('disabled', true);
            $(visDate).next('input').val($(visDate).val());
          }
          if (options.volume_units && volumeUnits) {
            volumeUnits.val(options.volume_units);
            volumeUnits.attr('disabled', true);
            $(volumeUnits).next('input').val($(volumeUnits).val());
          }
          if (options.cpt_code && cptCode) {
            cptCode.val(options.cpt_code);
            cptCode.attr('readonly', true);
          }
          if (options.npi_number && npiNumber) {
            npiNumber.val(options.npi_number);
            npiNumber.attr('readonly', true);
          }
          if (options.icd10_code && icd10Code) {
            icd10Code.val(options.icd10_code);
            icd10Code.attr('readonly', true);
          }

          itemType.attr('disabled', true);
          productName.removeClass('invalid');
          manufacturer.removeClass('invalid');
          itemType.removeClass('invalid');
          $('.remove-error').addClass('hidden');
        },
      });
    } else {
      // eslint-disable-next-line max-len
      enableFields(productName, manufacturer, itemType, ndcCode, doseVolume, visDate, volumeUnits, cptCode, npiNumber, icd10Code);
    }
  }

  updateHiddenEventType() {
    $(this.eventTypeTarget).next('input').val($(this.eventTypeTarget).val());
  }

  setManufacturerId() {
    $('input[id=supply_inventory_manufacturer_id]').val($(this.manufacturerTarget).val());
  }

  updateItemType() {
    $(this.itemTypeTarget).next('input').val($(this.itemTypeTarget).val());
    this.vaccineTarget.disabled = this.itemTypeTarget.value !== '';
  }

  toggleVaccineFields() {
    const requiredSpan = '<span class="field-required">*</span>';

    if (this.hasVaccineTarget && this.vaccineTarget.value) {
      if (this.hasDoseVolumeTarget && this.doseVolumeTarget.dataset.required === 'true') {
        this.doseVolumeTarget.setAttribute('required', true);
        const label = this.doseVolumeTarget.previousElementSibling;
        if (label.querySelector('span.field-required') === null) {
          label.innerHTML += requiredSpan;
        }
      }
      if (this.hasVolumeUnitsTarget && this.volumeUnitsTarget.dataset.required === 'true') {
        this.volumeUnitsTarget.setAttribute('required', true);
        const label = this.volumeUnitsTarget.previousElementSibling;
        if (label.querySelector('span.field-required') === null) {
          label.innerHTML += requiredSpan;
        }
      }
    } else {
      if (this.hasDoseVolumeTarget) {
        this.doseVolumeTarget.removeAttribute('required');
        const label = this.doseVolumeTarget.previousElementSibling;
        if (label.querySelector('span.field-required') != null) {
          label.removeChild(label.querySelector('span.field-required'));
        }
      }
      if (this.hasVolumeUnitsTarget) {
        this.volumeUnitsTarget.removeAttribute('required');
        const label = this.volumeUnitsTarget.previousElementSibling;
        if (label.querySelector('span.field-required') != null) {
          label.removeChild(label.querySelector('span.field-required'));
        }
      }
    }

    if (!(this.volumeUnitsTarget.dataset.required === 'true')) this.volumeUnitsRequiredTarget.hidden = true;
  }
}

// eslint-disable-next-line max-len
function enableFields(productName, manufacturer, itemType, ndcCode, doseVolume, visDate, volumeUnits, cptCode, npiNumber, icd10Code) {
  if (productName) productName.attr('readonly', false);
  if (manufacturer) manufacturer.attr('disabled', false);
  if (itemType) itemType.attr('readonly', false);
  if (ndcCode) ndcCode.attr('readonly', false);
  if (doseVolume) doseVolume.attr('readonly', false);
  if (visDate) visDate.next('input').attr('disabled', false);
  if (volumeUnits) volumeUnits.attr('disabled', false);
  if (npiNumber) npiNumber.attr('readonly', false);
  if (icd10Code) icd10Code.attr('readonly', false);
  if (cptCode) cptCode.attr('readonly', false);
}
