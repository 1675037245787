import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['fieldContainer', 'blankFields', 'remove'];

  connect() {
    this.refreshDeletable();
  }

  addAnother(e) {
    e.preventDefault();

    if (this.blankFieldsTargets[0].getElementsByClassName('cl-date-picker').length > 1) {
      this.blankFieldsTargets[0].getElementsByClassName('cl-date-picker')[1].remove();
    }

    this.fieldContainerTarget.insertAdjacentHTML('beforeend', this.blankFieldsTargets[0].innerHTML);
    this.refreshDeletable();
  }

  removeElement(event) {
    $(event.currentTarget).parents('.nestedClinicDate')[0].remove();
    this.refreshDeletable();
  }

  refreshDeletable() {
    this.removeTargets.forEach((removeTarget) => {
      if (this.filterVisible().length <= 1) {
        removeTarget.classList.add('hidden');
      } else {
        removeTarget.classList.remove('hidden');
      }
    });
  }

  filterVisible() {
    return Array.from(this.fieldContainerTarget.children).filter((child) => window.getComputedStyle(child).getPropertyValue('display'));
  }
}
