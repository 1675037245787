import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['breaksQuantity'];

  decrementBreaksQuantity() {
    const actualValue = parseInt((this.breaksQuantityTarget.value), 10);

    if (actualValue > 0) {
      this.breaksQuantityTarget.value = actualValue - 1;
    } else {
      this.breaksQuantityTarget.value = 0;
    }
  }

  incrementBreaksQuantity(e) {
    e.preventDefault();

    const actualValue = parseInt((this.breaksQuantityTarget.value), 10);
    this.breaksQuantityTarget.value = actualValue + 1;
  }
}
