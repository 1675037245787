import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal'];

  static classes = ['hidden'];

  yes() {
    this.dispatch('yesNo-YesClicked');
    this.closeModal();
  }

  no() {
    this.dispatch('yesNo-NoClicked');
    this.closeModal();
  }

  showModal() {
    this.modalTarget.classList.remove(this.hiddenClass);
  }

  closeModal() {
    this.modalTarget.classList.add(this.hiddenClass);
  }
}
