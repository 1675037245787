import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['cancelModal', 'cancelRecordButton', 'countyCode'];

  toggleDeleteModal(event) {
    this.cancelModalTarget.classList.toggle('hidden');
    this.cancelRecordButtonTarget.href = event.currentTarget.dataset.url;
  }

  handlePatientDisplayChange(event) {
    this.countyCodeTarget.classList.toggle('hidden', !event.target.checked);
  }
}
