/* eslint-disable no-param-reassign */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['requiredCheckbox'];

  connect() {
    this.update();
  }

  update() {
    const checkedCheckboxes = this.requiredCheckboxTargets.filter((el) => el.checked);
    if (checkedCheckboxes.length > 0) {
      this.requiredCheckboxTargets.forEach((el) => {
        el.required = false;
      });
    } else {
      this.requiredCheckboxTargets.forEach((el) => {
        el.required = true;
      });
    }
  }
}
